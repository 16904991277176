import Keycloak from 'keycloak-js'

import { MainRoutes } from 'constants/path'

import { CLIENT_ID, Endpoints, REALM } from 'consts'
import { matchRealmFromUrl } from 'utils/realmUtils'

class KeycloakService {
  initialized = false

  private readonly keycloak: Keycloak

  constructor() {
    const returnPath = localStorage.getItem(MainRoutes.BEFORE_RELOAD_KEY) || '/'
    const returnPathRealm = matchRealmFromUrl(returnPath)
    const authPath = window.location.pathname.match(MainRoutes.AUTHENTICATING)
    this.keycloak = new Keycloak({
      realm: authPath && returnPathRealm ? `grace-${returnPathRealm}` : REALM,
      url: `${Endpoints.KEYCLOAK}/auth`,
      clientId: CLIENT_ID,
    })
  }

  getInitKeycloakPromise = () =>
    this.initKeycloak().then((initialized) => {
      this.initialized = initialized
      return initialized
    })

  updateToken = () => this.keycloak.updateToken(5)
  refreshToken = () => {
    return this.keycloak.updateToken(300)
  }

  logout = (redirectUri: string | void) => {
    window.localStorage.removeItem(MainRoutes.BEFORE_RELOAD_KEY)
    this.keycloak.logout({
      redirectUri: redirectUri || window.location.origin,
    })
  }

  getKeycloakInstance = () => this.keycloak

  private initKeycloak = () => {
    return this.keycloak.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    })
  }
}

const keycloakService = new KeycloakService()

export default keycloakService
