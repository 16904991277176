import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 4px;
  padding: 16px;
`

const ImageContainer = styled.img`
  height: 100%;
  width: 100%;
  max-width: 130px;
  max-height: 130px;
`

const ControlContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const RequirementsText = styled.span<{ error: boolean }>`
  font-size: 11px;
  text-align: center;
  line-height: 14px;
  white-space: pre-line;
  margin: 5px;
  color: ${({ error }) => (error ? 'red' : '')};
`

export { Container, ImageContainer, ControlContainer, RequirementsText }
