import { Endpoints, HttpMethods } from 'consts'

import { AchievementDTO } from '../achievements.types'

const baseUrl = `${Endpoints.GOVERNANCE}/api/v1`

export const getAchievementImageUrl = (achievementID: string) => `${baseUrl}/achievements/${achievementID}/picture`
export const getAchievementImageUrlByProject = (projectName: string, achievementID: string) =>
  `${baseUrl}/projects/${projectName}/achievements/${achievementID}/picture`

const api = {
  getAchievements: () => ({
    url: `${baseUrl}/achievements`,
    method: HttpMethods.GET,
  }),
  getAchievementsByProject: (projectName: string) => ({
    url: `${baseUrl}/projects/${projectName}/achievements`,
    method: HttpMethods.GET,
  }),
  postAchievement: (body: AchievementDTO) => ({
    url: `${baseUrl}/achievements`,
    method: HttpMethods.POST,
    body,
  }),
  putAchievement: (body: AchievementDTO) => ({
    url: `${baseUrl}/achievements/${body.id}`,
    method: HttpMethods.PUT,
    body,
  }),
  postAchievementPicture: (achievementID: string, body: FormData) => ({
    url: `${baseUrl}/achievements/${achievementID}/picture`,
    method: HttpMethods.POST,
    body,
    headers: {
      'Content-Type': undefined,
    },
  }),
  deleteAchievementPicture: (achievementID: string) => ({
    url: `${baseUrl}/achievements/${achievementID}/picture`,
    method: HttpMethods.DELETE,
    headers: {
      'Content-Type': undefined,
    },
  }),
}

export default api
