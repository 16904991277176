import React, { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

import { Field, FieldProps, useFormikContext } from 'formik'

import { Grid, Typography } from '@mui/material'

import { SelectChangeEvent } from '@mui/material/Select/Select'

import TextFormField from 'components/Form/TextFormField'

import ResourcesTitle from 'components/FormProject/components/ResourcesTitle'
import Select from 'components/Input/Select/Select'

import { usePermissionForCurrentUser } from 'services/api/users/users.hooks'
import { Permissions } from 'services/api/users/utils/user.types'

import { getGovStreams } from 'services/govStreams/govStreams.selectors'

import { getLifeCycleModelsProjectList } from 'services/lifecycles/lifeCycleModels.selectors'
import { getLifeCycleStageTitle } from 'services/lifecycles/utils/getLifeCycleStageTitle'

import { ModelSchemaNames, RegistryModelFormValues } from './RegistryModelForm'
import GlobalListSelectField from './components/GlobalListSelectField'

import { ListTypes } from '../Lists/components/ListForm'

import { GridRow, RowsSectionWrapper } from '../FormColumnsChips/FormColumnsChips.styles'

interface Props {
  projectName: string
  edit?: boolean
}

const RegistryModelFormProperties: React.FC<Props> = ({ projectName, edit }) => {
  const { t } = useTranslation()
  const { values, setValues } = useFormikContext<RegistryModelFormValues>()
  const canReadStreams = usePermissionForCurrentUser({ [projectName]: [Permissions.GOV_STREAMS] })
  const streams = useSelector(getGovStreams)
  const projectLifeCycles = useSelector(getLifeCycleModelsProjectList)

  const streamsOptions = useMemo(() => {
    const options = streams.map(({ govStream }) => ({ label: govStream.name, value: govStream.id }))
    options.unshift({ label: t('none'), value: '' })
    return options
  }, [streams, t])

  const stream = useMemo(() => {
    return streams.find((item) => item.govStream.id === values[ModelSchemaNames.govStream])
  }, [streams, values])

  const handleChangeStream = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as string
      if (!value) {
        setValues({
          ...values,
          [ModelSchemaNames.govStream]: '',
          [ModelSchemaNames.lcmStage]: '',
        })
      } else {
        const stream = streams.find(({ govStream }) => govStream.id === value)
        setValues({
          ...values,
          [ModelSchemaNames.govStream]: value,
          [ModelSchemaNames.lcmStage]: stream?.govStream.lcmStage || '',
        })
      }
    },
    [setValues, values, streams],
  )

  return (
    <RowsSectionWrapper container>
      <GridRow item container xs={12} spacing={3}>
        <Grid item container>
          {canReadStreams && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">{t('models.governance')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field name={ModelSchemaNames.govStream}>
                  {({ field }: FieldProps) => (
                    <Select
                      {...field}
                      fullWidth
                      required
                      displayEmpty
                      id="governanceStreamSelect"
                      label={t('models.governanceStream')}
                      onChange={handleChangeStream}
                      options={streamsOptions}
                    />
                  )}
                </Field>
              </Grid>
              {values[ModelSchemaNames.lcmStage] && (
                <Grid item xs={6}>
                  <TextFormField
                    disabled
                    id="lcmStageField"
                    name={ModelSchemaNames.lcmStage}
                    label={t('models.lifeCycleStage')}
                    value={getLifeCycleStageTitle({
                      lifeCycles: projectLifeCycles,
                      stream: stream?.govStream,
                    })}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <ResourcesTitle>{t('models.technicalProps')}</ResourcesTitle>
        </Grid>
        <Grid item xs={6}>
          <GlobalListSelectField
            id="modelTypeFormField"
            projectName={projectName}
            name={ModelSchemaNames.modelType}
            label={t('inputs.modelType')}
            listType={ListTypes.modelType}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            id="imageNameFormField"
            name={ModelSchemaNames.name}
            label={t('inputs.modelID')}
            required
            disabled={edit}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            id="modelRegistrySourceFormField"
            name={ModelSchemaNames.modelRegistrySource}
            label={t('inputs.modelRegistrySource')}
          />
        </Grid>
        <Grid item xs={12}>
          <ResourcesTitle>{t('models.data')}</ResourcesTitle>
        </Grid>
        <Grid item xs={6}>
          <TextFormField id="dataOriginFormField" name={ModelSchemaNames.dataOrigin} label={t('inputs.dataOrigin')} />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            id="testDataSetFormField"
            name={ModelSchemaNames.testDataSet}
            label={t('inputs.testDataSet')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            id="trainingDataFormField"
            name={ModelSchemaNames.trainingData}
            label={t('inputs.trainingData')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFormField
            id="validationProcedureFormField"
            name={ModelSchemaNames.validationProcedure}
            label={t('inputs.validationProcedure')}
          />
        </Grid>
      </GridRow>
    </RowsSectionWrapper>
  )
}

export default RegistryModelFormProperties
