import { Id } from 'react-toastify'

import { prepareAction } from 'store/prepareAction'

import { PlatformNotification } from './notifications.types'

interface UpdateNotificationPayload extends Partial<PlatformNotification> {
  id: Id
}

export const notificationCreate = prepareAction<PlatformNotification>('NOTIFICATION_CREATE')
export const notificationUpdate = prepareAction<UpdateNotificationPayload>('NOTIFICATION_UPDATE')
export const notificationDelete = prepareAction<Id>('NOTIFICATION_DELETE')
export const notificationDeleteAll = prepareAction('NOTIFICATION_DELETE_ALL')
