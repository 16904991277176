import React from 'react'

import { useTranslation } from 'react-i18next'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { FormControl, MenuItem, SelectProps, SvgIconTypeMap, TextField } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export interface SelectOption {
  value: string
  label: string
  icon?: React.ReactNode
}

export interface SelectInputProps extends SelectProps {
  options: SelectOption[]
  helperText?: any
  mainIcon?: OverridableComponent<SvgIconTypeMap>
}

const Select: React.FC<SelectInputProps> = ({
  disabled = false,
  label,
  error,
  helperText: message,
  onChange,
  options,
  required,
  value,
  className,
  fullWidth,
  mainIcon,
  displayEmpty,
  ...inputProps
}) => {
  const { t } = useTranslation()
  const helperText = message || (required ? `* ${t('validation.required')}` : null)
  const icon = mainIcon ? mainIcon : ArrowDropDownIcon
  // TODO: Figure out how to handle the placement of the popup menu in order to be more convenient for the user
  return (
    <FormControl
      className={className}
      variant="outlined"
      required={required}
      error={error}
      fullWidth={fullWidth}
      size="small"
    >
      <TextField
        {...inputProps}
        InputLabelProps={{ shrink: displayEmpty }}
        disabled={disabled}
        select
        label={label}
        value={value}
        error={!!error}
        // @ts-ignore
        onChange={onChange}
        helperText={helperText}
        SelectProps={{ IconComponent: icon, displayEmpty }}
        size="small"
      >
        {options.map(({ label, value, icon }) => (
          <MenuItem key={value} value={value} data-cy="select-option">
            {icon || null}
            {label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}

export default Select
