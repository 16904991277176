import React, { memo } from 'react'

import { FormikProps, withFormik } from 'formik'
import i18n from 'i18n'
import getOr from 'lodash/fp/getOr'
import * as yup from 'yup'

import FlexForm from 'components/FormProject/components/FlexForm'
import { GovStreamSummary } from 'services/govStreams/govStreams.types'

import { ModelImage } from 'services/modelDeployments/models/models.types'

import CreateRegistryModelForm from './CreateRegistryModelForm'
import EditRegistryModelForm from './EditRegistryModelForm'
import { ModelAchievementsTypes } from './RegistryModelFormAchievements'
import { getModelAchievementsFromDto } from './utils'

interface OwnProps {
  id: string
  projectName: string
  streams: GovStreamSummary[]
  onSubmit: (values: RegistryModelFormValues) => void
  canCreateHosted?: boolean
  onDismiss: () => void
  model?: ModelImage
  edit?: boolean
}

export enum ModelSchemaNames {
  displayName = 'displayName',
  name = 'name',
  logo = 'logo',
  version = 'version',
  folder = 'folder',
  description = 'description',
  modelType = 'modelType',
  celeryModel = 'celeryModel',
  tags = 'tags',
  external = 'external',
  type = 'type',
  baseImageName = 'baseImageName',
  additionalGpuBaseImageName = 'additionalGpuBaseImageName',
  govStream = 'govStream',
  lcmStage = 'lcmStage',
  businessOwner = 'businessOwner',
  techOwner = 'techOwner',
  responsible = 'responsible',
  industry = 'industry',
  countryJurisdiction = 'countryJurisdiction',
  dataOrigin = 'dataOrigin',
  testDataSet = 'testDataSet',
  trainingData = 'trainingData',
  validationProcedure = 'validationProcedure',
  modelRegistrySource = 'modelRegistrySource',
  achievements = 'achievements',
}

export type ModelAchievements = Record<ModelAchievementsTypes, string[]>

export interface RegistryModelFormValues {
  [ModelSchemaNames.displayName]: string
  [ModelSchemaNames.name]: string
  [ModelSchemaNames.logo]: string
  [ModelSchemaNames.version]: string
  [ModelSchemaNames.folder]: string
  [ModelSchemaNames.description]: string
  [ModelSchemaNames.modelType]: string
  [ModelSchemaNames.tags]: string[]
  [ModelSchemaNames.external]: boolean
  [ModelSchemaNames.type]: string
  [ModelSchemaNames.celeryModel]: boolean
  [ModelSchemaNames.baseImageName]?: string
  [ModelSchemaNames.additionalGpuBaseImageName]?: string
  [ModelSchemaNames.govStream]: string
  [ModelSchemaNames.lcmStage]: string
  [ModelSchemaNames.businessOwner]: string
  [ModelSchemaNames.techOwner]: string
  [ModelSchemaNames.responsible]: string
  [ModelSchemaNames.industry]: string[]
  [ModelSchemaNames.countryJurisdiction]: string[]
  [ModelSchemaNames.modelRegistrySource]: string
  [ModelSchemaNames.dataOrigin]: string
  [ModelSchemaNames.trainingData]: string
  [ModelSchemaNames.testDataSet]: string
  [ModelSchemaNames.validationProcedure]: string
  [ModelSchemaNames.achievements]: ModelAchievements
}

const getInitialValues = (model?: ModelImage, streams?: GovStreamSummary[]): RegistryModelFormValues => {
  const govStreamId = model?.govStreamId
  const stream = streams?.find(({ govStream }) => govStream.id === govStreamId)

  return {
    [ModelSchemaNames.displayName]: getOr('', ModelSchemaNames.displayName, model),
    [ModelSchemaNames.name]: getOr('', ModelSchemaNames.name, model),
    [ModelSchemaNames.logo]: getOr('', ModelSchemaNames.logo, model),
    [ModelSchemaNames.version]: getOr('', ModelSchemaNames.version, model),
    [ModelSchemaNames.folder]: getOr('', ModelSchemaNames.folder, model),
    [ModelSchemaNames.description]: getOr('', ModelSchemaNames.description, model),
    [ModelSchemaNames.modelType]: getOr('', ModelSchemaNames.modelType, model),
    [ModelSchemaNames.tags]: getOr([], ModelSchemaNames.tags, model),
    [ModelSchemaNames.external]: getOr(true, ModelSchemaNames.external, model),
    [ModelSchemaNames.type]: getOr('', ModelSchemaNames.type, model),
    [ModelSchemaNames.celeryModel]: getOr(false, ModelSchemaNames.celeryModel, model),
    [ModelSchemaNames.govStream]: getOr('', 'govStreamId', model),
    [ModelSchemaNames.lcmStage]: stream?.govStream.lcmStage || '',
    [ModelSchemaNames.businessOwner]: getOr('', ModelSchemaNames.businessOwner, model),
    [ModelSchemaNames.techOwner]: getOr('', ModelSchemaNames.techOwner, model),
    [ModelSchemaNames.responsible]: getOr('', ModelSchemaNames.responsible, model),
    [ModelSchemaNames.industry]: getOr([], ModelSchemaNames.industry, model),
    [ModelSchemaNames.countryJurisdiction]: getOr([], ModelSchemaNames.countryJurisdiction, model),
    [ModelSchemaNames.modelRegistrySource]: getOr('', ModelSchemaNames.modelRegistrySource, model),
    [ModelSchemaNames.dataOrigin]: getOr('', ModelSchemaNames.dataOrigin, model),
    [ModelSchemaNames.trainingData]: getOr('', ModelSchemaNames.trainingData, model),
    [ModelSchemaNames.testDataSet]: getOr('', ModelSchemaNames.testDataSet, model),
    [ModelSchemaNames.validationProcedure]: getOr('', ModelSchemaNames.validationProcedure, model),
    [ModelSchemaNames.achievements]: getModelAchievementsFromDto(model?.achievements),
  }
}

const createValidationSchema = ({ edit }: OwnProps) =>
  yup
    .object<RegistryModelFormValues>({
      [ModelSchemaNames.displayName]: yup.string().min(3).label('modelName').required(),
      [ModelSchemaNames.name]: yup
        .string()
        .matches(/^[a-z0-9-]+$/)
        .min(3)
        .max(36)
        .required(),
      [ModelSchemaNames.logo]: yup.mixed().nullable(),
      [ModelSchemaNames.description]: yup.string(),
      [ModelSchemaNames.version]: yup
        .string()
        .matches(/^[0-9]+(([0-9]|\.|-)*[0-9]+)*$/, { message: i18n.t('validation.validImageVersion') })
        .required(),
      [ModelSchemaNames.folder]: yup.string(),
      [ModelSchemaNames.celeryModel]: yup.boolean(),
      [ModelSchemaNames.tags]: yup.array().of(yup.string()),
      [ModelSchemaNames.modelType]: yup.string(),
      [ModelSchemaNames.govStream]: yup.string(),
      [ModelSchemaNames.lcmStage]: yup.string(),
      [ModelSchemaNames.businessOwner]: yup.string(),
      [ModelSchemaNames.techOwner]: yup.string(),
      [ModelSchemaNames.responsible]: yup.string(),
      [ModelSchemaNames.industry]: yup.array().of(yup.string()),
      [ModelSchemaNames.countryJurisdiction]: yup.array().of(yup.string()),
      [ModelSchemaNames.modelRegistrySource]: yup.string(),
      [ModelSchemaNames.dataOrigin]: yup.string(),
      [ModelSchemaNames.trainingData]: yup.string(),
      [ModelSchemaNames.testDataSet]: yup.string(),
      [ModelSchemaNames.validationProcedure]: yup.string(),
      [ModelSchemaNames.achievements]: yup.object(),
    })
    .shape(
      {
        [ModelSchemaNames.folder]: yup.string().when([ModelSchemaNames.external], {
          is: (external: boolean) => !external && !edit,
          then: (schema) => schema.required(),
        }),
      },
      [['modelType', 'folder']],
    )
    .shape(
      {
        [ModelSchemaNames.baseImageName]: yup.string().when([ModelSchemaNames.external], {
          is: (external: boolean) => !external && !edit,
          then: (schema) => schema.required(),
        }),
      },
      [['modelType', 'folder']],
    )

const enhance = withFormik<OwnProps, RegistryModelFormValues>({
  validationSchema: createValidationSchema,
  handleSubmit: ({ ...values }, { props }) => {
    return props.onSubmit({ ...values })
  },
  mapPropsToValues: ({ model, streams }) => getInitialValues(model, streams),
})

type Props = OwnProps & FormikProps<RegistryModelFormValues>

const RegistryModelForm: React.FC<Props> = ({ id, onDismiss, projectName, edit, model }) => {
  return (
    <FlexForm id={`${id}Form`} autoComplete="off" withYScroll>
      {edit && model ? (
        <EditRegistryModelForm projectName={projectName} id={id} onDismiss={onDismiss} model={model} />
      ) : (
        <CreateRegistryModelForm projectName={projectName} onClose={onDismiss} />
      )}
    </FlexForm>
  )
}

export default enhance(memo(RegistryModelForm))
