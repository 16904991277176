import React, { memo } from 'react'
import { Img } from 'react-image'

import isNumber from 'lodash/isNumber'
import styled from 'styled-components'

import { BrokenImage } from '@mui/icons-material'

import { SvgIconProps } from '@mui/material'

import Loading from 'components/Loading'

interface StyledBrokenImageProps extends SvgIconProps {
  size: number | string
}

const StyledBrokenImage = styled(({ size, ...rest }: StyledBrokenImageProps) => <BrokenImage {...rest} />)`
  color: inherit;
  font-size: ${({ size }) => (isNumber(size) ? `${size}px` : size)};
`

interface Props extends Partial<Omit<HTMLImageElement, 'src'>> {
  altSize: number | string
  src: string | string[]
  size?: string
}

const Image: React.FC<Props> = ({ altSize = 40, ...other }) => (
  // @ts-ignore
  <Img unloader={<StyledBrokenImage size={altSize} />} loader={<Loading size={altSize} />} decode={false} {...other} />
)

export default memo(Image)
