import styled from 'styled-components'

import { Stepper } from '@mui/material'

const StyledStepper = styled(Stepper)`
  background-color: ${({ theme }) => theme.palette.custom.stepper.background};
  padding: 24px 40px 20px 40px;
`

export default StyledStepper
