import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastOptions } from 'react-toastify'

import { getOr } from 'lodash/fp'

import useActionCreator from 'hooks/useActionCreator'
import { RootState } from 'store/root.reducer'

import { notificationCreate, notificationUpdate } from '../notifications.actions'
import { getNotificationById } from '../notifications.selectors'
import { NotificationBodyType, NotificationState } from '../notifications.types'

interface Options {
  text: string
  icon: JSX.Element
  bodyType: NotificationBodyType
  subText?: any
  toastProps?: ToastOptions
}

const useCreateNotification = ({ toastProps, text, subText, icon, bodyType }: Options) => {
  const createNotification = useActionCreator(notificationCreate)
  const updateNotification = useActionCreator(notificationUpdate)
  const { pathname } = useLocation()
  const type = getOr('', 'type', toastProps)
  const id = getOr('', 'toastId', toastProps)
  const notification = useSelector((state: RootState) => getNotificationById(state, id))
  const actionToRun = notification ? updateNotification : createNotification

  const state =
    type === 'error'
      ? NotificationState.ERROR
      : getOr('', 'type.displayName', icon).includes('CircularProgress')
      ? NotificationState.PENDING
      : NotificationState.SUCCESS

  useEffect(() => {
    actionToRun({
      text,
      subText,
      state,
      id,
      path: pathname,
      bodyType,
    })
  }, [text, subText, actionToRun, state, id, pathname, bodyType])
}

export default useCreateNotification
