import { GovStream } from 'services/govStreams/govStreams.types'
import { LifeCycleModel } from 'services/lifecycles/lifeCycleModel.types'

interface Props {
  lifeCycles?: LifeCycleModel[]
  stream?: GovStream
}

export const getLifeCycleStageTitle = ({ lifeCycles = [], stream }: Props) => {
  if (!stream) return ''

  const lifeCycle = lifeCycles.find(({ id }) => id === stream.lcmId)
  if (!lifeCycle) return ''

  const lifeCycleStage = lifeCycle.stages?.find(({ name }) => name === stream.lcmStage)
  return lifeCycleStage?.title || ''
}
