import styled from 'styled-components'

const InlineInputContainers = styled.div<{ marginBottom?: string }>`
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  margin-bottom: ${({ marginBottom = '24' }) => marginBottom}px;

  &:last-child {
    margin-bottom: inherit;
  }
`

export const FullWidthInlineInputContainer = styled(InlineInputContainers)`
  width: 100%;
`

export default InlineInputContainers
