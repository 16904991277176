import { ImageCategory } from 'services/registryImages/registryImages.types'

import { failureAction, moduleAction, prepareAction, requestAction, successAction } from 'store/prepareAction'

import { PlatformImage } from './platformImages.types'

export interface InstallImagesPayload {
  images: string[]
}

const platformImagesAction = moduleAction('PLATFORM_IMAGES')

export const PLATFORM_IMAGES_GET = platformImagesAction('GET')
export const platformImagesFetchRequest = prepareAction<ImageCategory[]>(requestAction(PLATFORM_IMAGES_GET))
export const platformImagesFetchSuccess = prepareAction<PlatformImage[]>(successAction(PLATFORM_IMAGES_GET))
export const platformImagesFetchFailure = prepareAction(failureAction(PLATFORM_IMAGES_GET))

const platformImageAction = moduleAction('PLATFORM_IMAGE')

export const PLATFORM_IMAGE_GET = platformImageAction('GET')
export const platformImageFetchRequest = prepareAction<string>(requestAction(PLATFORM_IMAGE_GET))
export const platformImageFetchSuccess = prepareAction<PlatformImage>(successAction(PLATFORM_IMAGE_GET))
export const platformImageFetchFailure = prepareAction(failureAction(PLATFORM_IMAGE_GET))

export const PLATFORM_IMAGE_PUT = platformImageAction('PUT')
export const platformImagesUpdateRequest = prepareAction<InstallImagesPayload>(requestAction(PLATFORM_IMAGE_PUT))
export const platformImagesUpdateSuccess = prepareAction<PlatformImage[]>(successAction(PLATFORM_IMAGE_PUT))
export const platformImagesUpdateFailure = prepareAction(failureAction(PLATFORM_IMAGE_PUT))

export const PLATFORM_IMAGE_DELETE = platformImageAction('DELETE')

export const platformImageDeleteRequest = prepareAction<string>(requestAction(PLATFORM_IMAGE_DELETE))
export const platformImageDeleteSuccess = prepareAction<string>(successAction(PLATFORM_IMAGE_DELETE))
export const platformImageDeleteFailure = prepareAction(failureAction(PLATFORM_IMAGE_DELETE))
