import React, { memo } from 'react'

import { ChipProps as MuiChipProps } from '@mui/material/Chip'

import PopoverWithTrigger from 'components/PopoverWithTrigger'

import ChipWithoutPopover, { ChipColor } from './ChipWithoutPopover'

export interface ChipProps extends Omit<MuiChipProps, 'avatar' | 'ref' | 'innerRef' | 'bgColor'> {
  avatar?: string | string[]
  onDelete?: (props: any) => void
  popoverContentRenderer?: React.ComponentType<any>
  popoverContentProps?: Record<string, unknown>
  chipColor?: ChipColor
  bgColor?: string
  noMargin?: boolean
}

const Chip: React.FC<ChipProps> = ({
  avatar,
  label,
  onDelete,
  chipColor,
  bgColor,
  noMargin,
  popoverContentRenderer: Content,
  popoverContentProps,
  ...chipProps
}) => {
  if (Content) {
    return (
      <PopoverWithTrigger
        trigger={ChipWithoutPopover}
        triggerProps={{
          clickable: true,
          avatar,
          label,
          onDelete,
          chipColor,
          ...chipProps,
        }}
        content={Content}
        contentProps={{
          avatar,
          name: label,
          ...chipProps,
          ...popoverContentProps,
        }}
      />
    )
  }
  return (
    <ChipWithoutPopover
      avatar={avatar}
      label={label}
      bgColor={bgColor}
      onDelete={onDelete ? () => onDelete({ ...chipProps, label }) : undefined}
      chipColor={chipColor}
      noMargin={noMargin}
      {...chipProps}
    />
  )
}

export default memo(Chip)
