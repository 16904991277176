import React from 'react'

import styled from 'styled-components'

import Typography from '@mui/material/Typography'

const StyledResourcesTitle = styled(Typography)`
  padding: 16px 0;
`

const ResourcesTitle: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => {
  return (
    <StyledResourcesTitle className={className} variant="h6">
      {children}
    </StyledResourcesTitle>
  )
}

export default ResourcesTitle
