import { Form } from 'formik'
import styled from 'styled-components'

const FlexForm = styled(Form)<{ withYScroll?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: space-between;
  ${({ withYScroll }) => withYScroll && 'overflow-y: scroll'};
`

export default FlexForm
