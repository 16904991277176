import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CreateRegistryModelFooter from './CreateRegistryModelFooter'
import CreateRegistryModelStepper from './CreateRegistryModelStepper'
import RegistryModelFormAchievements from './RegistryModelFormAchievements'
import RegistryModelFormGeneral from './RegistryModelFormGeneral'
import RegistryModelFormHosted from './RegistryModelFormHosted'
import RegistryModelFormProperties from './RegistryModelFormProperties'
import DialogContent from '../DialogContent'

interface Props {
  projectName: string
  onClose: () => void
}

const CreateRegistryModelForm: React.FC<Props> = ({ projectName, onClose }) => {
  const { t } = useTranslation()

  const [activeStepIndex, setActiveStepIndex] = useState(0)

  const steps = [
    {
      label: t('models.registerModel'),
      component: <RegistryModelFormGeneral projectName={projectName} />,
    },
    {
      label: t('models.modelProperties'),
      component: <RegistryModelFormProperties projectName={projectName} />,
    },
    {
      label: t('models.graceHosted'),
      component: <RegistryModelFormHosted projectName={projectName} />,
    },
    {
      label: t('models.achievements'),
      component: <RegistryModelFormAchievements />,
    },
  ]

  const countOfSteps = steps.length
  const isLastStep = activeStepIndex === countOfSteps - 1
  const ActiveStep = steps[activeStepIndex].component

  const handleNext = () => {
    if (!isLastStep) setActiveStepIndex((prev) => ++prev)
  }

  const handleBack = () => {
    if (!!activeStepIndex) setActiveStepIndex((prev) => --prev)
  }

  return (
    <>
      <CreateRegistryModelStepper steps={steps} activeStep={activeStepIndex} />
      <DialogContent>{ActiveStep}</DialogContent>
      <CreateRegistryModelFooter
        onNext={handleNext}
        onBack={handleBack}
        onExit={onClose}
        activeTabIndex={activeStepIndex}
        countOfSteps={countOfSteps}
      />
    </>
  )
}

export default CreateRegistryModelForm
