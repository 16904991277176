import React from 'react'
import { Flip, Id, toast, ToastOptions } from 'react-toastify'

import styled from 'styled-components'

import CheckCircle from '@mui/icons-material/CheckCircle'
import Close from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'

import ShowHideNotification from 'components/ActionToastButton/ShowHideNotification'
import tracker from 'utils/tracker'

import NotificationBody from '../components/Notifications/NotificationBody'

interface CreateNotificationProps {
  icon?: JSX.Element
  text: string
  subText?: any
  options?: ToastOptions
}

interface CreateNotificationPropsWithId extends CreateNotificationProps {
  toastId: Id
}

interface CreateUpdateNotificationProps extends CreateNotificationPropsWithId {
  icon: JSX.Element
}

interface ShowToastProps {
  icon: JSX.Element
  text: string
  subText?: any
  options: ToastOptions
}

export const NotificationAutoClose = {
  QUICK: 3000,
  SLOW: 30000,
}

export default class Notification {
  static show = ({ options, ...rest }: ShowToastProps): any => toast(<NotificationBody {...rest} />, options)

  static showExpandable = ({ options, ...rest }: CreateNotificationProps) =>
    toast(<ShowHideNotification {...rest} />, {
      hideProgressBar: true,
      autoClose: false,
      ...options,
    })

  static update = ({ options = {}, toastId, ...rest }: CreateUpdateNotificationProps) =>
    toast.update(toastId, {
      render: <NotificationBody {...rest} />,
      autoClose: options.autoClose || NotificationAutoClose.QUICK,
      transition: Flip,
      ...options,
    })

  static close = (toastId?: Id) => toast.dismiss(toastId)

  static isActive = (toastId: Id) => toast.isActive(toastId)

  static showInProgress = (props: CreateNotificationProps) =>
    Notification.show({
      icon: props.icon ? props.icon : <CircularProgress color="inherit" size={24} />,
      ...props,
      options: { autoClose: false, ...props.options },
    })

  static showFailure = (props: CreateNotificationProps, track = true) => {
    if (track) {
      tracker.grace.errorNotification(props.text, props.subText as string)
    }
    return Notification.show({
      icon: props.icon ? props.icon : <StyledClose />,
      ...props,
      options: { autoClose: false, type: toast.TYPE.ERROR, ...props.options },
    })
  }

  static showSuccess = (props: CreateNotificationProps) =>
    Notification.show({
      icon: <CheckCircle color="secondary" />,
      ...props,
      options: { type: toast.TYPE.SUCCESS, ...props.options },
    })

  static updateInProgress = (props: CreateNotificationPropsWithId) =>
    Notification.update({
      icon: props.icon ? props.icon : <CircularProgress color="inherit" size={24} />,
      ...props,
      options: { autoClose: false, ...props.options },
    })

  static updateSuccess = (props: CreateNotificationPropsWithId) =>
    Notification.update({
      icon: props.icon ? props.icon : <CheckCircle color="secondary" />,
      ...props,
      options: { type: toast.TYPE.SUCCESS, ...props.options },
    })

  static updateFailure = (props: CreateNotificationPropsWithId) => {
    tracker.grace.errorNotification(props.text, props.subText as string)
    return Notification.update({
      icon: props.icon ? props.icon : <StyledClose />,
      ...props,
      options: { type: toast.TYPE.ERROR, ...props.options },
    })
  }
}

const StyledClose = styled(Close)`
  color: white;
`
