import React, { memo, useCallback, useState } from 'react'

import { ToastOptions } from 'react-toastify'

import styled from 'styled-components'

import { Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import Transition from 'components/DialogRoot/components/SelectFolder/Transition'
import useCreateNotification from 'services/notifications/hooks/useCreateNotification'
import { NotificationBodyType } from 'services/notifications/notifications.types'

import { NotificationHeading, SecondRow } from 'components/Notifications/NotificationBody.styles'

import ActionToastButton from './ActionToastButton'

interface Props {
  text: string
  subText?: JSX.Element
  toastProps?: ToastOptions
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Spinner = styled(CircularProgress)`
  margin-top: 14px;
  margin-left: 14px;
`
Spinner.displayName = 'CircularProgress'

const ShowHideNotification: React.FC<Props> = ({ text, subText, toastProps }) => {
  const [isSecondRowVisible, setIsSecondRowVisible] = useState(false)

  const handleShowHideClick: React.MouseEventHandler = useCallback(
    (e: any) => {
      e.stopPropagation()
      setIsSecondRowVisible(!isSecondRowVisible)
    },
    [isSecondRowVisible],
  )
  const icon = <Spinner color="secondary" size={24} />
  useCreateNotification({ text, icon, subText, toastProps, bodyType: NotificationBodyType.EXPANDABLE })

  return (
    <Grid container spacing={1} alignItems="flex-start" data-cy="notificationBody">
      <Grid item xs={1} container justifyContent="center">
        {icon}
      </Grid>
      <Grid item xs={11}>
        <NotificationHeading>
          <Wrapper>{text}</Wrapper>
          {subText ? (
            <ActionToastButton text={isSecondRowVisible ? 'Hide info' : 'Show info'} onClick={handleShowHideClick} />
          ) : null}
        </NotificationHeading>
        {subText && (
          <Transition in={isSecondRowVisible}>
            <SecondRow dark>
              <Typography variant="body2" color="inherit">
                {subText}
              </Typography>
            </SecondRow>
          </Transition>
        )}
      </Grid>
    </Grid>
  )
}

export default memo(ShowHideNotification)
