import React from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined'

import { Badge } from '@mui/material'

import WithTooltip from 'components/WithTooltip'

const FlashIcon = styled(OfflineBoltOutlinedIcon)<{ size?: string }>`
  height: ${({ size }) => (size ? size : '0.7em')};
  color: ${({ theme }) => theme.palette.custom.lightGrey3};
`

interface Props {
  className?: string
  size?: string
  title?: string
  hasError?: boolean
}

const ConditionIcon: React.FC<Props> = ({ className, size, title, hasError }) => {
  const { t } = useTranslation()

  return (
    <WithTooltip title={title ? title : t('assessments.conditionEnabledTooltip')}>
      <Badge className={className} color="secondary" variant="dot" invisible={!hasError}>
        <FlashIcon size={size} />
      </Badge>
    </WithTooltip>
  )
}

export default ConditionIcon
