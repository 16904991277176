import React from 'react'

import styled from 'styled-components'

import { Typography, TypographyProps } from '@mui/material'

type WrapperProps = {
  id: string
  children: React.ReactNode
}

const ChipsHorizontalGroup = styled.div<WrapperProps>`
  &:not(:first-child) {
    margin-left: 24px;
  }
`

const centeredBlock = `
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

interface ChipsListLabelProp extends TypographyProps {
  centered?: boolean
}

const ChipsListLabel = styled(({ centered, ...rest }: ChipsListLabelProp) => <Typography {...rest} />)`
  color: ${({ theme }) => theme.palette.custom.lightGrey2};
  flex: 1;
  display: inline-block;
  font-size: 10.5px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  word-break: keep-all;
  ${({ centered }) => (centered ? centeredBlock : null)}
`

export { ChipsHorizontalGroup, ChipsListLabel }
