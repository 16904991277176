import { useMatch } from 'react-router-dom'

const useProjectName = () => {
  const match = useMatch<keyof { name?: string }, string>('/dashboard/projects/:name/*')
  let projectName: string | undefined = undefined

  if (match) {
    projectName = match?.params?.name
  }
  return projectName
}

export default useProjectName
