import React, { useState } from 'react'

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFormikContext } from 'formik'

import { Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'

import { DialogTypes } from 'consts'
import useActionCreator from 'hooks/useActionCreator'
import { getActiveAchievementsList } from 'services/achievements/achievements.selectors'
import { dialogOpened } from 'services/dialog/actions'

import { RegistryModelAchievement } from 'services/modelDeployments/models/models.types'

import { ModelSchemaNames, RegistryModelFormValues } from './RegistryModelForm'
import AchievementAccordionItem from './components/AchievementAccordionItem'
import ModelAchievementsListPlaceholder from './components/ModelAchievementsListPlaceholder'

export enum ModelAchievementsTypes {
  OBTAINED = 'obtained',
  RETRY = 'retry',
}

interface Props {
  modelAchievements?: RegistryModelAchievement[]
}

const RegistryModelFormAchievements: React.FC<Props> = ({ modelAchievements }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = useFormikContext<RegistryModelFormValues>()
  const [expanded, setExpanded] = useState<string | false>(false)

  const openDialog = useActionCreator(dialogOpened)

  const achievementsList = useSelector(getActiveAchievementsList)

  const { achievements } = values
  const { obtained, retry } = achievements

  const handleAddAchievements = (type: ModelAchievementsTypes) => {
    if (type === ModelAchievementsTypes.OBTAINED) {
      openDialog(DialogTypes.SELECT_MODEL_ACHIEVEMENTS, {
        items: achievementsList.filter(({ id }) => !retry.includes(id)),
        selected: obtained,
        onSelect: (values: string[]) => {
          setFieldValue(ModelSchemaNames.achievements, {
            ...achievements,
            [ModelAchievementsTypes.OBTAINED]: values,
          })
        },
      })
    } else if (type === ModelAchievementsTypes.RETRY) {
      openDialog(DialogTypes.SELECT_MODEL_ACHIEVEMENTS, {
        items: achievementsList.filter(({ id }) => !obtained.includes(id)),
        selected: retry,
        onSelect: (values: string[]) => {
          setFieldValue(ModelSchemaNames.achievements, {
            ...achievements,
            [ModelAchievementsTypes.RETRY]: values,
          })
        },
      })
    }
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = achievements[source.droppableId as ModelAchievementsTypes]
      const destColumn = achievements[destination.droppableId as ModelAchievementsTypes]
      const [removed] = sourceColumn.splice(source.index, 1)
      destColumn.splice(destination.index, 0, removed)
      setFieldValue(ModelSchemaNames.achievements, {
        ...achievements,
        [source.droppableId]: sourceColumn,
        [destination.droppableId]: destColumn,
      })
    } else {
      const column = achievements[source.droppableId as ModelAchievementsTypes]
      const [removed] = column.splice(source.index, 1)
      column.splice(destination.index, 0, removed)
      setFieldValue(ModelSchemaNames.achievements, {
        ...achievements,
        [source.droppableId]: column,
      })
    }
  }

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, id: string, type: ModelAchievementsTypes) => {
    e.preventDefault()
    if (expanded === id) setExpanded(false)
    if (type === ModelAchievementsTypes.OBTAINED) {
      setFieldValue(ModelSchemaNames.achievements, {
        ...achievements,
        [ModelAchievementsTypes.OBTAINED]: [
          ...achievements[ModelAchievementsTypes.OBTAINED].filter((fieldID) => fieldID !== id),
        ],
      })
    } else if (type === ModelAchievementsTypes.RETRY) {
      setFieldValue(ModelSchemaNames.achievements, {
        ...achievements,
        [ModelAchievementsTypes.RETRY]: [
          ...achievements[ModelAchievementsTypes.RETRY].filter((fieldID) => fieldID !== id),
        ],
      })
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Grid container spacing={2}>
        {Object.entries(achievements).map(([type, achievementsList]) => (
          <Droppable key={type} droppableId={type}>
            {(provided) => (
              <Grid item xs={6}>
                <Grid item container xs={12}>
                  <Typography variant="h6">{t(`achievements.types.${type}`)}</Typography>
                  <Button
                    color="secondary"
                    onClick={() => handleAddAchievements(type as ModelAchievementsTypes)}
                    data-cy={`add-${type}-achievements`}
                  >
                    {t('addNew')}
                  </Button>
                </Grid>
                <div ref={provided.innerRef} {...provided.droppableProps} style={{ minHeight: '200px' }}>
                  <Grid item xs={12} mt={1}>
                    {achievementsList.length === 0 ? (
                      <ModelAchievementsListPlaceholder />
                    ) : (
                      achievementsList.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <AchievementAccordionItem
                                type={type}
                                achievementID={item}
                                addedAt={modelAchievements?.find(({ achId }) => achId === item)?.addedAt}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                onDelete={handleDelete}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    )}
                  </Grid>
                </div>
              </Grid>
            )}
          </Droppable>
        ))}
      </Grid>
    </DragDropContext>
  )
}

export default RegistryModelFormAchievements
