import React, { memo, useEffect, useRef, useState } from 'react'

import { Popover as MuiPopover } from '@mui/material'
import { PopoverProps } from '@mui/material/Popover'

interface Props {
  trigger: string | React.ComponentType<any>
  triggerProps?: any
  popover?: React.ComponentType<any>
  popoverProps?: Partial<PopoverProps>
  content: React.ComponentType<any>
  contentProps?: any
  onOpen?: (isOpen: boolean) => any
}

const defaultPopoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

const PopoverWithTrigger: React.FC<Props> = ({
  trigger: Trigger,
  triggerProps = {},
  popover: Popover = MuiPopover,
  popoverProps = defaultPopoverProps,
  content: Content,
  contentProps = {},
  onOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const triggerRef = useRef<HTMLDivElement | null>()

  useEffect(() => {
    onOpen && onOpen(!!anchorEl)
  }, [anchorEl, onOpen])

  const html = document.querySelector('html')
  const addGlobalClass = () => {
    html && html.classList.add('popover')
  }
  const removeGlobalClass = () => {
    html && html.classList.remove('popover')
  }

  const handleClose = (e: React.BaseSyntheticEvent) => {
    removeGlobalClass()
    setAnchorEl(null)
  }

  const handleClick: React.MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (anchorEl) {
      setAnchorEl(null)
      removeGlobalClass()
    } else {
      setAnchorEl(triggerRef.current || null)
      addGlobalClass()
    }
  }

  return (
    <>
      <span ref={triggerRef as any}>
        <Trigger onClick={handleClick} {...triggerProps} />
      </span>
      <Popover anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} {...popoverProps}>
        {Content && <Content close={handleClose} {...contentProps} />}
      </Popover>
    </>
  )
}

export default memo(PopoverWithTrigger)
