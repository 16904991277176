import React from 'react'

import styled, { css } from 'styled-components'

import { Help } from '@mui/icons-material'

export const HelpIcon = styled(({ mini, ...props }: any) => <Help {...props} />)<{
  mini?: boolean | 'insideNotification'
}>`
  ${({ mini }) => {
    if (mini === true) {
      return css`
        color: ${({ theme }) => theme.palette.custom.lightGrey4};
        font-size: 16px;
      `
    } else if (mini === 'insideNotification') {
      return css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      `
    } else {
      return ''
    }
  }}
`
