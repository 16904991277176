import styled from 'styled-components'

import { Step } from '@mui/material'

const StyledStep = styled(Step)`
  .MuiStepConnector-root {
    top: 16px;
    left: calc(-50% + 28px);
    right: calc(50% + 28px);
  }
`
export default StyledStep
