import React, { memo } from 'react'

import { ToastOptions } from 'react-toastify/dist/types'

import Typography from '@mui/material/Typography'

import useCreateNotification from 'services/notifications/hooks/useCreateNotification'
import { NotificationBodyType } from 'services/notifications/notifications.types'

import { NotificationHeading, SecondRow } from './NotificationBody.styles'

interface Props {
  text: string
  subText?: any
  icon: JSX.Element
  toastProps?: ToastOptions
}

const NotificationBody: React.FC<Props> = ({ text, subText, icon, toastProps }) => {
  useCreateNotification({ text, icon, subText, toastProps, bodyType: NotificationBodyType.NORMAL })

  return (
    <div data-cy="notificationBody">
      <NotificationHeading>
        {text}
        {icon}
      </NotificationHeading>
      {subText && (
        <SecondRow>
          <Typography variant="body2">{subText}</Typography>
        </SecondRow>
      )}
    </div>
  )
}

export default memo(NotificationBody)
