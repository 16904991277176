import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { Button } from '@mui/material'

import { RegistryModelFormValues } from './RegistryModelForm'

const ConfirmButton = styled(Button)`
  margin-left: 24px;
`

const Footer = styled.div`
  width: 100%;
  padding: 16px 24px 24px;
  display: flex;
  justify-content: space-between;
`

interface Props {
  onNext: () => void
  onBack: () => void
  onExit: () => void
  activeTabIndex: number
  countOfSteps: number
}

const CreateRegistryModelFooter: React.FC<Props> = ({ onNext, onBack, onExit, activeTabIndex, countOfSteps }) => {
  const { t } = useTranslation()
  const { isValid, submitForm } = useFormikContext<RegistryModelFormValues>()

  const isLastStep = activeTabIndex === countOfSteps - 1

  return (
    <Footer>
      <Button color="secondary" onClick={onBack} disabled={!activeTabIndex || !isValid} data-cy="prev">
        {t('previous')}
      </Button>

      <div>
        <Button color="secondary" onClick={onExit} data-cy="exit">
          {t('cancel')}
        </Button>
        <ConfirmButton
          variant="contained"
          color="secondary"
          disabled={!isValid}
          onClick={isLastStep ? submitForm : onNext}
          data-cy={isLastStep ? 'create' : 'next'}
        >
          {isLastStep ? t('finish') : t('continue')}
        </ConfirmButton>
      </div>
    </Footer>
  )
}

export default memo(CreateRegistryModelFooter)
