import React, { memo } from 'react'

import { useField } from 'formik'
import styled from 'styled-components'

import TextField, { TextFieldProps } from 'components/Input/TextField'

interface Props extends TextFieldProps {
  name: string
}

const StyledTextField = styled(TextField)`
  &:not(:last-child) {
    margin-bottom: 22px;
  }
`

const TextFormField: React.FC<Props> = ({ name, helperText, ...props }) => {
  const [field, meta] = useField<string>(name)
  return (
    <StyledTextField
      {...field}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : helperText}
      fullWidth
      {...props}
      size="small"
    />
  )
}

export default memo(TextFormField)
