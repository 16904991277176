import i18n from 'i18next'

const agoWithLabels = (date: string | Date | number, labels: string[][]) => {
  const seconds = Math.floor((new Date().valueOf() - new Date(date).valueOf()) / 1000)
  if (isNaN(seconds)) return
  const divisions = [31536000, 2592000, 86400, 3600, 60]
  let interval = seconds / divisions[0]
  for (let agoIndx = 0; agoIndx < labels.length; agoIndx++) {
    if (interval >= 1 && interval < 2) return `1${labels[agoIndx][0]}`
    else if (interval >= 2) return Math.floor(interval) + labels[agoIndx][1]
    else if (agoIndx < labels.length - 1) interval = seconds / divisions[agoIndx + 1]
    else if (seconds <= 1) return `1${labels[agoIndx][0]}`
    else return Math.floor(seconds) + labels[agoIndx][1]
  }
}

export const formatDateToAgo = (date: string | Date | number) => {
  const agoTimes = [
    [` ${i18n.t('date.yearAgo')}`, ` ${i18n.t('date.yearsAgo')}`],
    [` ${i18n.t('date.monthAgo')}`, ` ${i18n.t('date.monthsAgo')}`],
    [` ${i18n.t('date.dayAgo')}`, ` ${i18n.t('date.daysAgo')}`],
    [` ${i18n.t('date.hourAgo')}`, ` ${i18n.t('date.hoursAgo')}`],
    [` ${i18n.t('date.minuteAgo')}`, ` ${i18n.t('date.minutesAgo')}`],
    [` ${i18n.t('date.secondAgo')}`, ` ${i18n.t('date.secondsAgo')}`],
  ]

  return agoWithLabels(date, agoTimes)
}

export const formatDateToAgoShort = (date: string | Date | number) => {
  const agoTimes = [
    [`${i18n.t('date.yearsAgoShort')}`, `${i18n.t('date.yearsAgoShort')}`],
    [`${i18n.t('date.monthsAgoShort')}`, `${i18n.t('date.monthsAgoShort')}`],
    [`${i18n.t('date.daysAgoShort')}`, `${i18n.t('date.daysAgoShort')}`],
    [`${i18n.t('date.hoursAgoShort')}`, `${i18n.t('date.hoursAgoShort')}`],
    [`${i18n.t('date.minutesAgoShort')}`, `${i18n.t('date.minutesAgoShort')}`],
    [`${i18n.t('date.secondsAgoShort')}`, `${i18n.t('date.secondsAgoShort')}`],
  ]

  return agoWithLabels(date, agoTimes)
}

export default formatDateToAgo
