import { GovStreamEvent, GovStreamSummary } from 'services/govStreams/govStreams.types'
import { RootState } from 'store/root.reducer'

const root = (state: RootState) => state.govStreams

const govStreamsById = (state: RootState) => root(state).byId
const govStreamsIds = (state: RootState) => root(state).ids
const govStreamEvents = (state: RootState) => root(state).events

export const getGovStreamsIds = (state: RootState): string[] | [] => govStreamsIds(state)
export const getGovStreamsById = (state: RootState): { [key: string]: GovStreamSummary } => govStreamsById(state)
export const getGovStreamEvents = (state: RootState): GovStreamEvent[] | [] => govStreamEvents(state)
export const getGovStreams = (state: RootState) => Object.values(govStreamsById(state))
