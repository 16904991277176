import styled from 'styled-components'

import MuiTab from '@mui/material/Tab'
import MuiTabs, { TabsProps } from '@mui/material/Tabs'

export const SidebarTabs = styled(MuiTabs).attrs((props: TabsProps) => ({
  indicatorColor: 'secondary',
  textColor: 'primary',
  ...props,
}))`
  overflow: initial;
  padding: 0;
  min-height: auto;
`

export const Tab = styled(MuiTab)`
  padding: 6px 20px 12px;
  min-width: auto;
  min-height: auto;
  font-size: 11.5px;
  letter-spacing: 0.7px;
  line-height: 14px;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const SidebarTab = styled(Tab)`
  &.Mui-selected {
    color: #e36f32;
  }
`

export default SidebarTabs
