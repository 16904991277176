import React, { memo } from 'react'

import { Button } from '@mui/material'

interface OwnProps {
  text: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

type Props = OwnProps

const ActionToastButton: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Button color="secondary" onClick={onClick}>
      {text}
    </Button>
  )
}

export default memo(ActionToastButton)
