import React from 'react'

import styled from 'styled-components'

import ImagePlaceHolder from 'assets/images/achievement-picture-placeholder.svg'
import { useImageUrl } from 'hooks/useImageUrl'
import { getAchievementImageUrlByProject } from 'services/achievements/utils/achievements.api'
import useProjectName from 'services/projectProperties/hooks/useProjectName'

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 10px;
`

const AchievementPicture: React.FC<{ id: string }> = ({ id }) => {
  const projectName = useProjectName()
  const imageUrl = useImageUrl(projectName && getAchievementImageUrlByProject(projectName, id))

  return <Image alt="picture" src={imageUrl || ImagePlaceHolder} />
}

export default AchievementPicture
