import { v4 as uuid } from 'uuid'

import { Card } from 'services/cards/cards.types'

export const newCard: Card = {
  id: uuid(),
  tabs: [
    {
      id: uuid(),
      displayName: 'Overview',
      widgets: [],
    },
  ],
}
