import { DeploymentState } from '../../../modelDeployments/deployments/deployments.types'

/**
 * Permissions structured by level name, name can be: global or projectName
 */
export interface PermissionsByLevel {
  [level: string]: Permissions[] | undefined
}

/**
 * Required permissions structured by level, which can be global, project or any level
 */
export interface RequiredPermissionsByLevel extends PermissionsByLevel {
  _global?: Permissions[]
  _project?: Permissions[]
  _anyLevel?: Permissions[]
}

export enum Permissions {
  APPLICATIONS_EDIT = 'applications:edit',
  APPLICATIONS_READ = 'applications:read',
  APPLICATIONS_MANAGE = 'applications:manage',
  ASSESSMENTS = 'assessments',
  ASSESSMENTS_ANSWERS_FILL = 'assessments:answers:fill',
  ASSESSMENTS_ANSWERS_VIEW = 'assessments:answers:view',
  ASSESSMENTS_MANAGE = 'assessments:manage',
  ASSETS_INSTALL_IN_PROJECT = 'assets:install:project',
  ASSETS_INSTALL_IN_PLATFORM = 'assets:install:platform',
  ASSETS_MANAGE = 'assets:manage',
  CERTIFICATES = 'certificates',
  DASHBOARD_BUILDER = 'dashboardBuilder',
  DASHBOARD_OVERVIEW = 'dashboard:overview',
  DATASOURCES_READ = 'datasources:read',
  DATASOURCES_MANAGE = 'datasources:manage',
  DATA_CATALOG = 'datacatalog',
  DEPLOYMENTS_MANAGE = 'deployments:manage',
  DESIGN = 'design',
  ENDPOINTS_EDIT = 'endpoints:edit',
  ENDPOINTS_READ = 'endpoints:read',
  GLOBAL_ASSESSMENTS_ANSWERS_VIEW = 'globalAssessments:answers:view',
  GLOBAL_ASSESSMENTS_MANAGE = 'globalAssessments:manage',
  GLOBAL_LIFECYCLES_EDIT = 'globalLifecyclesEdit',
  GLOBAL_LIFECYCLES_VIEW = 'globalLifecyclesView',
  GLOBAL_RULES_MANAGE = 'globalRules:manage',
  GOV_STREAMS = 'govstream',
  GOV_STREAMS_MANAGE = 'govstream:manage',
  IMAGES = 'images',
  MANAGE_USER_ACCESS_TOKEN = 'manageUserAccessToken',
  MLOPS_OVERVIEW = 'mlops:overview',
  MODELS_DEPLOYED_READ = 'models_deployed:read',
  MODELS_REGISTRY_READ = 'models_registry:read',
  MODELS_REGISTRY_CREATE_HOSTED = 'models_registry:create_hosted',
  MODELS_SNAPSHOT_READ = 'models_snapshot:read',
  MODELS_DEPLOY = 'models:deploy',
  MODELS_MANAGE = 'models:manage',
  MODEL_ASSESSMENTS_MANAGE = 'model_assessments:manage',
  MODEL_ASSESSMENTS_FILL = 'model_assessments:fill',
  MODEL_CARD_CONFIGURE = 'modelCardConfigure',
  MODEL_CARD_READ = 'modelCard:read',
  NOTIFICATION_CENTER_MANAGE = 'notificationCenter:manage',
  NOTIFICATION_CENTER_READ = 'notificationCenter:read',
  PIPELINES = 'pipelines',
  PIPELINES_MANAGE = 'pipelines:manage',
  PLATFORM_RESOURCES = 'platformResources',
  PLATFORM_SETTINGS_READ = 'platformSettingsRead',
  PROJECTS_VIEW = 'projects:view',
  PROJECTS_CREATE = 'projects:create',
  PROJECTS_MANAGE = 'projects:manage',
  PROJECT_ASSESSMENTS_MANAGE = 'projectAssessments:manage',
  PROJECT_DESCRIPTION_EDIT = 'projectDescription:edit',
  PROJECT_LIFECYCLES_EDIT = 'projectLifecyclesEdit',
  PROJECT_LIFECYCLES_VIEW = 'projectLifecyclesView',
  PROJECT_REGISTRY_VIEW = 'projectRegistryView',
  PROJECT_USERS_EDIT = 'projectUsers:edit',
  PROPERTIES_EDIT = 'properties:edit',
  PROPERTY_STORE_READ = 'properties:read',
  REPORTS = 'reports',
  RULES_MANAGE = 'rules:manage',
  RULE_SETS = 'ruleSets',
  TOKEN_REGENERATE = 'token:regenerate',
  USERS = 'users',
  WORKSPACES_ALL = 'workspaces:all',
  WORKSPACES_MANAGE_ACTIVATIONS = 'workspaces:manage:activations',
  WORKSPACE_PROJECT_ACTIVATION = 'workspace:project:activation',
  LISTS_MANAGE = 'lists:manage',
  ONBOARDING_ASSESSMENTS_MANAGE = 'onboardingAssessments:manage',
  REALMS = 'realms',
  ACHIEVEMENTS_MANAGE = 'achievements:manage',
}

export enum UserRoles {
  ADMIN = 'admin',
  PLATFORM_ADMIN = 'platformAdmin',
  // @todo these are now project roles
  AI_LEAD = 'aiLead',
  PROJECT_ADMIN = 'projectAdmin',
  DATA_SCIENTIST = 'dataScientist',
  DATA_ENGINEER = 'dataEngineer',
  MODEL_DEPLOYER = 'modelDeployer',
  COMPLIANCE_OFFICER = 'complianceOfficer',
  PROJECT_COMPLIANCE_OFFICER = 'projectComplianceOfficer',
  PROJECT_VIEWER = 'projectViewer',
  APPLICATION_VIEWER = 'applicationViewer',
}

export interface User {
  id: string
  email: string
  firstName: string
  language: string
  login: string
  primaryRole?: string
  roles: UserRolesByLevel
  surname: string
  uuid?: string
  lastErrorMsg: string
  lastUserMsg: string
  state: DeploymentState
  canCreateUserTokens: boolean
}

export interface UserRole {
  id: string
  displayName: string
}

export interface UserRolesByLevel {
  _global: UserRole[]

  [projectName: string]: UserRole[]
}
