import compose from 'lodash/fp/compose'
import get from 'lodash/fp/get'
import isEqual from 'lodash/fp/isEqual'

import { DeploymentState } from '../deployments.types'

type deploymentStatusResolver = (deploymentStatus: DeploymentState) => boolean

const { CREATE, DELETING, READY, DELETED, INVALID, HEALING, UPDATE, RECOVER } = DeploymentState

export const isDeploymentStatusBeingCreated: deploymentStatusResolver = isEqual(CREATE)
export const isDeploymentStatusBeingInvalid: deploymentStatusResolver = isEqual(INVALID)
export const isDeploymentStatusBeingDeleted: deploymentStatusResolver = isEqual(DELETING)
export const isDeploymentStatusReady: deploymentStatusResolver = isEqual(READY)
export const isDeploymentStatusDone: deploymentStatusResolver = isEqual(DELETED)
export const isDeploymentStatusHealing: deploymentStatusResolver = isEqual(HEALING)
export const isDeploymentStatusUpdating: deploymentStatusResolver = isEqual(UPDATE)
export const isDeploymentStatusRecovering: deploymentStatusResolver = isEqual(RECOVER)

export const isDeploymentStatusPending: deploymentStatusResolver = (status) =>
  isDeploymentStatusBeingCreated(status) || isDeploymentStatusBeingDeleted(status)

export const isDeploymentBeingCreated = compose(isDeploymentStatusBeingCreated, get('state'))
export const isDeploymentReady = compose(isDeploymentStatusReady, get('state'))
export const isDeploymentBeingDeleted = compose(isDeploymentStatusBeingDeleted, get('state'))
export const isDeploymentInvalid = compose(isDeploymentStatusBeingInvalid, get('state'))
export const isDeploymentDone = compose(isDeploymentStatusDone, get('state'))
export const isDeploymentHealing = compose(isDeploymentStatusHealing, get('state'))
export const isDeploymentUpdating = compose(isDeploymentStatusUpdating, get('state'))
export const isDeploymentRecovering = compose(isDeploymentStatusRecovering, get('state'))
