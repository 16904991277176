import { createSelector } from 'reselect'

import { RootState } from 'store/root.reducer'

const root = (state: RootState) => state.achievements
const byId = (state: RootState) => root(state).byId
const ids = (state: RootState) => root(state).ids

export const getAchievementsById = (state: RootState) => root(state).byId
export const getAchievementsByName = createSelector(byId, ids, (byId, ids) =>
  Object.fromEntries(ids.map((id) => [byId[id].name, byId[id]])),
)
export const getAchievementsList = createSelector(byId, ids, (byId, ids) =>
  ids.map((id) => byId[id]).sort((a, b) => Number(b.active) - Number(a.active)),
)

export const getActiveAchievementsList = createSelector(getAchievementsList, (achievements) =>
  achievements.filter(({ active }) => active),
)

export const getAchievementByID = createSelector(
  byId,
  (_: RootState, id: string) => id,
  (byId, id) => byId[id],
)
