import React, { memo } from 'react'

import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

const AddChipButton: React.FC<Props> = ({ onClick, disabled }) => {
  return (
    <IconButton onClick={onClick} color="secondary" data-cy="addButton" size="large" disabled={disabled}>
      <AddIcon />
    </IconButton>
  )
}

export default memo(AddChipButton)
