import { createSelector } from 'reselect'

import { RootState } from 'store/root.reducer'

const root = (state: RootState) => state.lists
const byId = (state: RootState) => root(state).byId
const ids = (state: RootState) => root(state).ids

export const getLists = createSelector(byId, ids, (byId, ids) => ids.map((id) => byId[id]))
export const getListById = createSelector(
  byId,
  (_: RootState, listId: string) => listId,
  (byId, listId) => byId[listId],
)

export const getListEntries = (state: RootState) => root(state).listWithEntries?.entries
