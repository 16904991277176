import find from 'lodash/find'
import compose from 'lodash/fp/compose'
import reduce from 'lodash/fp/reduce'
import sortBy from 'lodash/fp/sortBy'
import uniq from 'lodash/fp/uniq'
import { createSelector } from 'reselect'

import { RootState } from 'store/root.reducer'

import { permissionsByRoleMap } from './utils/user.helpers'
import { Permissions, PermissionsByLevel, User, UserRoles, UserRolesByLevel } from './utils/user.types'

const usersById = (state: RootState) => state.users.byId
const usersIds = (state: RootState) => state.users.ids
export const getRoles = (state: RootState) => state.users.roles
export const getProjectRoles = (state: RootState) => state.users.projectRoles
export const getUserCreationSubscriptions = (state: RootState) => state.users.userCreationSubscriptions
const getUserAvatars = (state: RootState) => state.users.avatars

const getUsername = (state: RootState, ownProps: any) => ownProps.login

const getUsers = createSelector(usersById, usersIds, (byId, ids) => ids.map((id) => byId[id]))

export const getSortedUsers = createSelector(
  getUsers,
  sortBy((o: User) => o.login),
)

export const getUser = createSelector(getUsers, getUsername, (users, login) => find(users, { login }))

const getNames = (state: RootState, ownProps: any) => ownProps.names

export const getUsersDetails = createSelector(getUsers, getNames, (users, names) =>
  users.filter(({ login }) => names.includes(login)),
)

export const getLoggedUser = (state: RootState) => state.users.loggedUser

export const getLoggedUserLanguage = createSelector(getLoggedUser, (user) => (user ? user.language || 'en' : undefined))
export const getLoggedUserUUID = createSelector(getLoggedUser, (user) => (user && user?.uuid ? user.uuid : undefined))

export const getLoggedUserRoles = (state: RootState) => (getLoggedUser(state) || {}).roles || { _global: [] }

export const isCurrentUserAdmin = createSelector(getLoggedUserRoles, (roles) => {
  const adminRoles: string[] = [UserRoles.ADMIN, UserRoles.PROJECT_ADMIN, UserRoles.PLATFORM_ADMIN]
  return (roles as UserRolesByLevel)._global?.some((role) => adminRoles.includes(role.id))
})

export const isCurrentUserAdminOrPlatformAdmin = createSelector(getLoggedUserRoles, (roles) => {
  const rolesToSelect: string[] = [UserRoles.ADMIN, UserRoles.PLATFORM_ADMIN]
  return (roles as UserRolesByLevel)._global?.some((role) => rolesToSelect.includes(role.id))
})

const reduceRoles = (result: Permissions[], role: UserRoles) => {
  const permissionsByRole: Permissions[] = permissionsByRoleMap[role]
  return result.concat(permissionsByRole)
}
const createUniqPermissionsList = compose(uniq, reduce(reduceRoles, []))

export const getCurrentUserPermissions = createSelector(getLoggedUserRoles, (rolesByLevel: UserRolesByLevel) => {
  const permissions: PermissionsByLevel = { _global: [] }
  if (!rolesByLevel) {
    return permissions
  }
  for (const level in rolesByLevel) {
    if (rolesByLevel.hasOwnProperty(level)) {
      permissions[level] = createUniqPermissionsList(rolesByLevel[level]?.map((r) => r.id))
    }
  }
  return permissions
})

export const getUserAvatar = createSelector(
  getUserAvatars,
  (_: RootState, username: string) => username,
  (avatars, username) => avatars[username],
)
