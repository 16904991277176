import { createSelector } from 'reselect'

import { ModelImage } from 'services/modelDeployments/models/models.types'
import { RootState } from 'store/root.reducer'

import { DeploymentState } from '../deployments/deployments.types'
import { isDeploymentStatusPending } from '../deployments/utils/deploymentsStatusResolvers'

const root = (state: RootState) => state.modelDeployments.models
export const getModelsById: (state: RootState) => Record<string, ModelImage> = (state: RootState) => root(state).byId
const getModelsIds = (state: RootState) => root(state).ids
const getModelsLog = (state: RootState) => root(state).modelsLogById
export const getFolders = (state: RootState) => root(state).foldersTree

export const getModels = createSelector(getModelsById, getModelsIds, (byId, ids) => ids.map((id) => byId[id]))

export const getAppsModelsById = (state: RootState) => root(state).appsModelsById

export const getInternalDeployedModels = createSelector(getModels, (models) =>
  models.filter(({ external, state }) => !external && state === DeploymentState.READY),
)

export const getInternalModels = createSelector(getModels, (models) => models.filter(({ external }) => !external))

export const getModelById = createSelector(
  getModelsById,
  (_: RootState, id: string | null) => id,
  (modelsById, id) => (id ? modelsById[id] : null),
)

export const getPendingModels = createSelector(getModels, (models) =>
  models.filter(({ state }) => isDeploymentStatusPending(state)),
)

export const getModelLogById = createSelector(
  getModelsLog,
  (_: RootState, id: string) => id,
  (log, id) => log[id],
)

export const getCardsByID = (state: RootState) => root(state).cardsByID

export const getRegistryTabs = (state: RootState) => root(state).registryTabs
