import find from 'lodash/find'
import filter from 'lodash/fp/filter'
import { createSelector } from 'reselect'

import { Project } from './project.types'
import { RootState } from '../../store/root.reducer'
import { getLoggedUserUsername } from '../api/keycloak/keycloak.selectors'
import { isDeploymentStatusPending } from '../modelDeployments/deployments/utils/deploymentsStatusResolvers'

const root = (state: RootState) => state.projects
const projectsById = (state: RootState) => root(state).byId
export const projectsAccessById = (state: RootState) => root(state).projectAccessById
const projectsIds = (state: RootState) => root(state).ids
export const getProjectSubscriptions = (state: RootState) => root(state).projectSubscriptions
export const getPendingProjectsUsersStatus = (state: RootState) => root(state).pendingProjectsUsersStatus

export const getProjects = createSelector(projectsById, projectsIds, (byId, ids) => ids.map((id) => byId[id]))

export const getProjectName = (state: RootState, ownProps: { name: string }) => ownProps.name

const filterProjectsByUsernameAndGroupName = (projects: Project[], username: string) =>
  projects.filter((project) => project.users.includes(username))

export const getLoggedUserProjects = createSelector(
  getProjects,
  getLoggedUserUsername,
  filterProjectsByUsernameAndGroupName,
)
// @ts-ignore
export const getLoggedUserNotPendingProjects = createSelector<RootState, Project[], Project[]>(
  getLoggedUserProjects,
  filter(({ state }) => !isDeploymentStatusPending(state)),
)

export const getNotPendingProjects = createSelector(
  getProjects,
  filter(({ state }) => !isDeploymentStatusPending(state)),
)

export const getProjectsWithGpu = createSelector(
  getProjects,
  filter(({ resourcesStatus }) => !!resourcesStatus.total.gpu),
)

export const getProjectById = createSelector(projectsById, getProjectName, (byId, name) => byId[name])

export const getUsersByProjectName = createSelector(getProjectById, (project) => (project ? project.users : []))

export const getCurrentUserProjectByProjectName = createSelector(
  getLoggedUserProjects,
  getProjectName,
  (projects, name) => find(projects, { id: name }),
)

export const getActiveProject = (state: RootState) => root(state).activeProject

export const getPendingAssessmentsByProjectId = (state: RootState) => root(state).pendingAssessmentsById

export const getPendingProjectsUsersStatusByProject = createSelector(
  getPendingProjectsUsersStatus,
  (_: RootState, projectName: string) => projectName,
  (getPendingProjectsUsersStatus, projectName) => getPendingProjectsUsersStatus[projectName],
)
