import React from 'react'

import { useField } from 'formik'
import { noop } from 'lodash'

import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import { InputAdornment } from '@mui/material'

import TextField from 'components/Input/TextField'
import { DialogTypes } from 'consts'
import useActionCreator from 'hooks/useActionCreator'
import { dialogOpened } from 'services/dialog/actions'

import HelpLink from '../HelpLink/HelpLink'

interface Props {
  id: string
  name?: string
  projectName: string
  fieldLabel: string
  dialogTitle: string
  dialogHelp?: string
  fieldHelpLink?: string
}

const SelectFolderField: React.FC<Props> = ({
  id,
  name = 'folder',
  projectName,
  fieldLabel,
  dialogTitle,
  dialogHelp,
  fieldHelpLink,
}) => {
  const openDialog = useActionCreator(dialogOpened)
  const [field, fieldMeta, { setValue }] = useField<string>(name)

  const handleFolderSelect = (path: string) => {
    setValue(path)
  }

  const handleClear = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setValue('')
  }

  const handleSelectFolderClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.preventDefault()
    openDialog(DialogTypes.SELECT_FOLDER, {
      id,
      title: dialogTitle,
      help: dialogHelp,
      onFolderSelect: handleFolderSelect,
      onConfirm: noop,
      onDismiss: noop,
      projectName,
    })
  }

  return (
    <TextField
      {...field}
      onChange={noop}
      fullWidth
      error={fieldMeta.touched && !!fieldMeta.error}
      helperText={fieldMeta.touched && fieldMeta.error}
      label={fieldLabel}
      id={`${id}FormField`}
      required
      onClick={handleSelectFolderClick}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="end">
              {field.value ? <CancelIcon onClick={handleClear} /> : <AddIcon />}
            </InputAdornment>
            {fieldHelpLink && <HelpLink mini helpSection={fieldHelpLink} />}
          </>
        ),
      }}
    />
  )
}

export default SelectFolderField
