import styled from 'styled-components'

import { Typography, TypographyProps } from '@mui/material'

interface SubTextRowProps {
  dark?: boolean
}

export const NotificationHeading = styled(Typography).attrs((props: TypographyProps) => ({
  component: 'div',
  variant: 'body1',
  ...props,
}))`
  color: ${({ theme }) => theme.palette.common.white};
  padding: 14px 14px 14px 20px;
  min-height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SecondRow = styled.div<SubTextRowProps>`
  width: 100%;
  min-height: 52px;
  background-color: ${({ theme, dark }) => (dark ? theme.palette.primary.main : theme.palette.common.white)};
  color: ${({ theme, dark }) => (dark ? theme.palette.common.white : theme.palette.primary.main)};
  padding: 14px 14px 14px 20px;
  display: flex;
  justify-content: space-between;
`
