import { sanitizeName } from 'utils/sanitizeName'

const generateUniqueName = (input: string, usedNames: Set<string>, maxLength = 30) => {
  let name = sanitizeName(input, maxLength)

  let i = 2
  while (usedNames.has(name)) {
    name = name.replace(/(-\d+)?$/, '') + `-${i++}`
  }

  return name
}
export default generateUniqueName
