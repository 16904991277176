import React, { FC } from 'react'

import { useSelector } from 'react-redux'

import SelectFormField from 'components/Form/SelectFormField'
import { getCurrentUserProjectByProjectName } from 'services/projects/projects.selectors'
import { RootState } from 'store/root.reducer'

interface UserSelectFieldProps {
  id: string
  name: string
  label: string
  projectName: string
  required?: boolean
}
export const UserSelectField: FC<UserSelectFieldProps> = ({ projectName, name, id, label, required }) => {
  const project = useSelector((state: RootState) => getCurrentUserProjectByProjectName(state, { name: projectName }))

  const userOptions = (project?.users || []).map((user: string) => ({
    label: user,
    value: user,
  }))

  return <SelectFormField id={id} name={name} label={label} options={userOptions!} required={required} />
}
export default UserSelectField
