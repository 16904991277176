import styled from 'styled-components'

import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

import ConditionIcon from 'components/Assessments/Builder/components/ConditionIcon'

export const Wrapper = styled.div`
  margin-bottom: 8px;
  margin-top: 10px;
`

export const PageSummaryInner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const Controls = styled.div`
  margin-left: auto;
`

export const Accordion = styled(MuiAccordion)`
  background: transparent;
  &,
  &.Mui-expanded {
    box-shadow: none;
  }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
  background-color: #cfe8f8;
  border-radius: 4px;
  padding: 15px 8px 13px 8px;
  box-shadow:
    0 1px 5px rgba(0, 0, 0, 0.2),
    0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.14);
  opacity: 1;
  color: #6a6f70;
  &.Mui-expanded {
    min-height: 56px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    align-self: start;
    margin-top: 9px;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-expandIcon {
    color: inherit;
  }
`

export const AccordionDetails = styled(MuiAccordionDetails)`
  margin-left: 50px;
  padding: 0;
  flex-direction: column;
  align-items: stretch;
`

export const PageEmpty = styled(Typography).attrs({ variant: 'caption', align: 'center' })`
  display: block;
  padding: 16px 24px;
`

export const PageConditionIcon = styled(ConditionIcon)`
  margin-top: 21px;
  '& .MuiBadge-badge' {
    margin-top: 21px;
  }
`

export const StyledFormControl = styled(FormControl)`
  margin: 5px 5px 5px 0;
  min-width: max-content;
  height: 50px;
  select {
    &:focus {
      background-color: transparent;
    }
  }
`

export const StyledSelect = styled(Select)`
  margin-top: 5px;
  color: #1c2020;
  height: 40px;
  .MuiSelect-select {
    font-size: 14px;
    padding-right: 32px;
    ${({ value }) => value === 'none' && 'color: #91999B;'}
    &:focus {
      background-color: transparent;
    }
  }
  .MuiSelect-icon {
    font-size: 20px;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  padding-right: 30px;
`

export const StyledIconButton = styled(IconButton)`
  margin-right: 4px;
`
