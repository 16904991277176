import React, { FC, memo, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useField, useFormikContext } from 'formik'

import { FormControlLabel, Grid, Switch } from '@mui/material'

import { SelectChangeEvent } from '@mui/material/Select/Select'

import SelectFolderField from 'components/DialogRoot/SelectFolderField'
import Select from 'components/Input/Select/Select'
import useActionCreator from 'hooks/useActionCreator'
import { platformImagesFetchRequest } from 'services/platformImages/platformImages.actions'
import { getPlatformImages } from 'services/platformImages/platformImages.selectors'
import { ImageCategory } from 'services/registryImages/registryImages.types'

import { ModelSchemaNames, RegistryModelFormValues } from './RegistryModelForm'

import { GridRow, RowsSectionWrapper } from '../FormColumnsChips/FormColumnsChips.styles'

enum HostedTypes {
  dynamic = 'dynamic',
  dynamicCelery = 'dynamicCelery',
}

const RegistryModelFormHosted: FC<{ projectName: string }> = ({ projectName }) => {
  const [t] = useTranslation()
  const { values, setFieldValue, setFieldTouched } = useFormikContext<RegistryModelFormValues>()
  const isInternal = !values[ModelSchemaNames.external]

  const [multiImage, setMultiImage] = useState<boolean>(false)

  const [baseImageNameField, baseImageNameMeta] = useField<string>(ModelSchemaNames.baseImageName)
  const [additionalGpuBaseImageNameField, additionalGpuBaseImageNameMeta] = useField<string>(
    ModelSchemaNames.additionalGpuBaseImageName,
  )
  const fastApiImages = useSelector(getPlatformImages).filter((image) => image.type === 'fastapi')
  const fetchPlatformImages = useActionCreator(platformImagesFetchRequest)

  useEffect(() => {
    fetchPlatformImages([ImageCategory.MODELS])
  }, [fetchPlatformImages])

  const fastApiImagesOptions = useMemo(() => {
    return fastApiImages.map(({ label, name }) => ({ label, value: name }))
  }, [fastApiImages])

  const fastApiCPUImagesOptions = useMemo(() => {
    return fastApiImages.filter(({ withGpu }) => !withGpu).map(({ label, name }) => ({ label, value: name }))
  }, [fastApiImages])

  const fastApiGPUImagesOptions = useMemo(() => {
    return fastApiImages.filter(({ withGpu }) => withGpu).map(({ label, name }) => ({ label, value: name }))
  }, [fastApiImages])

  const handleChangeHosted = async (_: any, value: boolean) => {
    await setFieldValue(ModelSchemaNames.type, value ? 'fastapi' : '')
    await setFieldValue(ModelSchemaNames.folder, '')
    await setFieldValue(ModelSchemaNames.external, !value)
    await setFieldValue(ModelSchemaNames.baseImageName, undefined)
    await setFieldTouched(ModelSchemaNames.baseImageName, true, true)
  }

  const handleChangeType = (event: SelectChangeEvent<unknown>) => {
    setMultiImage(false)
    setFieldValue(ModelSchemaNames.additionalGpuBaseImageName, undefined)
    if (event.target.value === HostedTypes.dynamicCelery) {
      setFieldValue(ModelSchemaNames.celeryModel, true)
      return
    } else if (event.target.value === HostedTypes.dynamic) {
      setFieldValue(ModelSchemaNames.celeryModel, false)
    }
  }

  return (
    <RowsSectionWrapper container>
      <GridRow item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <FormControlLabel
            value={isInternal}
            onChange={handleChangeHosted}
            label={t('inputs.hosted')}
            control={<Switch checked={isInternal} />}
            labelPlacement="start"
            data-cy="hostedSwitch"
          />
        </Grid>
      </GridRow>
      {isInternal && (
        <GridRow item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <Select
              required
              fullWidth
              id="modelType"
              label={t('models.hostedType')}
              value={values[ModelSchemaNames.celeryModel] ? HostedTypes.dynamicCelery : HostedTypes.dynamic}
              onChange={handleChangeType}
              options={[
                { label: t('inputs.dynamic'), value: HostedTypes.dynamic },
                { label: t('inputs.dynamicCelery'), value: HostedTypes.dynamicCelery },
              ]}
            />
          </Grid>
          {isInternal && values.celeryModel && (
            <Grid item xs={6}>
              <FormControlLabel
                value={multiImage}
                onChange={() => setMultiImage((prev) => !prev)}
                control={<Switch data-cy="switchFormField-multiImage" />}
                label={t('inputs.multiImage')}
                labelPlacement="end"
              />
            </Grid>
          )}
        </GridRow>
      )}
      <GridRow item container justifyContent={values[ModelSchemaNames.external] ? 'start' : 'end'} xs={12} spacing={3}>
        {isInternal && (
          <>
            <Grid item xs={6}>
              <SelectFolderField
                id="selectModelFolder"
                projectName={projectName}
                fieldLabel={t('inputs.snapshotFolder')}
                dialogTitle={t('headers.selectFolder')}
                fieldHelpLink="model-development-and-deployment.md#model-deployment"
              />
            </Grid>
            {isInternal && (
              <Grid item xs={6}>
                <Select
                  {...baseImageNameField}
                  options={multiImage ? fastApiCPUImagesOptions : fastApiImagesOptions}
                  fullWidth
                  error={baseImageNameMeta.touched && !!baseImageNameMeta.error}
                  helperText={baseImageNameMeta.touched && baseImageNameMeta.error}
                  label={t(`applications.${multiImage ? 'CPUBaseImageName' : 'baseImageName'}`)}
                  id="baseImageNameFormField"
                  required
                />
              </Grid>
            )}
          </>
        )}
        {multiImage && isInternal && (
          <Grid item xs={6}>
            <Select
              {...additionalGpuBaseImageNameField}
              fullWidth
              required
              id="additionalGpuBaseImageNameField"
              label={t('applications.GPUBaseImageName')}
              options={fastApiGPUImagesOptions}
              error={additionalGpuBaseImageNameMeta.touched && !!additionalGpuBaseImageNameMeta.error}
              helperText={additionalGpuBaseImageNameMeta.touched && additionalGpuBaseImageNameMeta.error}
            />
          </Grid>
        )}
      </GridRow>
    </RowsSectionWrapper>
  )
}

export default memo(RegistryModelFormHosted)
