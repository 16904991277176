import { createAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import { moduleAction } from 'store/prepareAction'

const dialogAction = moduleAction('DIALOG')

const DIALOG_OPENED = dialogAction('OPENED')

export const dialogOpened = createAction(DIALOG_OPENED, (dialogType: string, dialogProps: any = {}, id = uuid()) => {
  return {
    payload: {
      id,
      dialogType,
      ...dialogProps,
    },
  }
})

export const dialogClosed = createAction('DIALOG_CLOSED', (payload: string) => ({ payload }))

export const closeAllDialogs = createAction('CLOSE_ALL_DIALOGS')
