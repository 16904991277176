import { createBrowserRouter } from 'react-router-dom'

// @ts-ignore
window._paq = window._paq || []

const enqueue = (...args: any[]) => {
  // @ts-ignore
  window._paq.push(args)
}

let config = {
  matomoUrl: 'https://matomo.2021.services',
  envDimensionId: 1,
}

export const init = (
  websiteId: number,
  router: ReturnType<typeof createBrowserRouter>,
  configOverrides?: Partial<typeof config>,
) => {
  config = { ...config, ...configOverrides }

  enqueue('setTrackerUrl', `${config.matomoUrl}/matomo.php`)
  enqueue('setSiteId', `${websiteId}`)
  enqueue('requireConsent')
  setConsentGiven(localStorage.getItem('trackingDisabled') !== 'true')
  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.async = true
  script.defer = true
  script.src = `${config.matomoUrl}/matomo.js`
  document.head.append(script)
  // @ts-ignore
  router.subscribe((state) => {
    if (state.historyAction !== 'REPLACE') {
      trackPageView()
    }
  })
  trackPageView()
}

export const setUserId = (userId: number | string) => {
  enqueue('setUserId', userId)
}

export const setConsentGiven = (isConsentGiven: boolean) => {
  enqueue(isConsentGiven ? 'setConsentGiven' : 'forgetConsentGiven')
}

let previousPath: string | null = null
export const trackPageView = () => {
  const { pathname, hostname, hash } = window.location
  const path = pathname + hash
  if (previousPath) {
    enqueue('setReferrerUrl', previousPath)
  }
  enqueue('setCustomUrl', path)
  enqueue('setDocumentTitle', `${hostname} - ${path}`)
  enqueue('setPagePerformanceTiming', 0)
  enqueue('setCustomDimension', config.envDimensionId, hostname)
  enqueue('enableLinkTracking')
  enqueue('trackPageView')
  previousPath = path
}

export const trackEvent = (category: string, action: string, name?: string, value?: number) => {
  const optionalParams = [name, value].filter((param) => param !== undefined)
  enqueue('trackEvent', category, action, ...optionalParams)
}
