import React, { memo, useMemo } from 'react'

import { IStyledComponent, Runtime } from 'styled-components'
import { ChipT } from 'types'

import { FormHelperText } from '@mui/material'

import AddChipButton from './AddChipButton'
import ChipsList from './ChipsList'

import { ChipsListLabel } from './InlineChips.styles'

type WrapperProps = {
  id: string
  children: React.ReactNode
}

interface Props {
  id?: string
  isActionDisabled?: boolean
  label?: string
  isPending?: boolean
  chips: ChipT[]
  helper?: React.ReactNode
  onAdd?: (item?: any) => void
  onDelete?: (item: any) => void
  popoverContentRenderer?: React.ComponentType<any>
  wrapper?: React.ComponentType<any> | IStyledComponent<Runtime, WrapperProps>
}

const InlineChips: React.FC<Props> = ({
  id = '',
  isActionDisabled = false,
  label: chipsLabel,
  isPending,
  chips = [],
  helper = null,
  onAdd = null,
  onDelete,
  popoverContentRenderer,
  wrapper,
}) => {
  const Wrapper = useMemo(() => {
    return wrapper ? wrapper : (props: WrapperProps) => <div {...props} />
  }, [wrapper])

  return (
    <Wrapper id={id}>
      {chipsLabel && <ChipsListLabel variant="overline">{chipsLabel}</ChipsListLabel>}
      <ChipsList
        chips={chips}
        onDelete={!isPending ? onDelete : undefined}
        popoverContentRenderer={popoverContentRenderer}
      />
      {onAdd && !isActionDisabled && <AddChipButton onClick={onAdd} disabled={isPending} />}
      {helper && <FormHelperText>{helper}</FormHelperText>}
    </Wrapper>
  )
}

export default memo(InlineChips)
