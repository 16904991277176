import { stringify } from 'qs'
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { Endpoints, HttpMethods } from 'consts'
import { Card } from 'services/cards/cards.types'
import { newCard } from 'services/cards/utils/card.consts'
import { AjaxFn, getAuthorizationHeaders } from 'utils/ajax'

import {
  CardFetchPayload,
  CardPutPayload,
  CopyModelImagePayload,
  DeleteModelImagePayload,
  deleteModelLogoPayload,
  FetchModelImagePayload,
  ModelImageLogsOpenPayload,
  modelImageUpdatePayload,
  PostModelImagePayload,
  postModelLogoPayload,
  PutRegistryTabsPayload,
} from '../models.actions'

const governanceBaseUrl = `${Endpoints.GOVERNANCE}/api/v1`
const appsBaseUrl = Endpoints.APPLICATIONS

export const getModelLogoUrl = (projectName?: string, imageID?: string) => {
  if (!projectName || !imageID) return ''
  return `${governanceBaseUrl}/projects/${projectName}/models/${imageID}/logo`
}

export const api = {
  fetchGovernanceModels: (projectName: string) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models`,
    method: HttpMethods.GET,
  }),
  fetchAppsModels: (projectName: string) => ({
    url: `${appsBaseUrl}/models/${projectName}`,
    method: HttpMethods.GET,
  }),
  fetchFolders: ({ projectName, path }: { projectName: string; path: string }) => ({
    url: `${appsBaseUrl}/files/${projectName}/list?folder=${path}`,
    method: HttpMethods.GET,
  }),
  postModelImage: ({ projectName, body }: PostModelImagePayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models`,
    method: HttpMethods.POST,
    body,
  }),
  fetchModelImage: ({ projectName, id }: FetchModelImagePayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/${id}`,
    method: HttpMethods.GET,
  }),
  updateModelImage: ({ projectName, id, body }: modelImageUpdatePayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/${id}`,
    method: HttpMethods.PUT,
    body,
  }),
  deleteModelImage: ({ projectName, id }: DeleteModelImagePayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/${id}`,
    method: HttpMethods.DELETE,
  }),
  copyModelImage: ({ projectName, id, body }: CopyModelImagePayload) => ({
    url: `${appsBaseUrl}/models/${projectName}/${id}/copy`,
    method: HttpMethods.PUT,
    body,
  }),
  openLogEvents: ({ projectName, id, queryOptions }: ModelImageLogsOpenPayload) => ({
    url: `${appsBaseUrl}/image/${projectName}/${id}/logs?${stringify(queryOptions)}`,
    method: HttpMethods.GET,
    params: {
      headers: {
        ...getAuthorizationHeaders(),
      },
    },
  }),
  createModelCard: ({ projectName, imageID }: CardFetchPayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/${imageID}/card`,
    method: HttpMethods.POST,
    body: { ...newCard, id: imageID },
  }),
  fetchModelCard: ({
    payload: { projectName, imageID },
    ajax,
  }: {
    payload: CardFetchPayload
    ajax: AjaxFn<{ body: Card }>
  }) => {
    return ajax({
      url: `${governanceBaseUrl}/projects/${projectName}/models/${imageID}/card`,
      method: HttpMethods.GET,
    }).pipe(
      map(({ response }) => ({ ...response?.body, id: imageID })),
      catchError((error) => {
        if (error.status === 404) {
          return ajax(api.createModelCard({ projectName, imageID })).pipe(map(() => ({ ...newCard, id: imageID })))
        }
        return throwError(error)
      }),
    )
  },
  putModelCard: ({ card, projectName, imageID }: CardPutPayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/${imageID}/card`,
    method: HttpMethods.PUT,
    body: card,
  }),
  postModelLogo: ({ projectName, imageID, bodyLogo }: postModelLogoPayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/${imageID}/logo`,
    method: HttpMethods.POST,
    body: bodyLogo,
    headers: {
      'Content-Type': undefined,
    },
  }),
  deleteModelLogo: ({ projectName, id: imageID }: deleteModelLogoPayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/${imageID}/logo`,
    method: HttpMethods.DELETE,
  }),
  fetchRegistryTabs: (projectName: string) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/tabs`,
    method: HttpMethods.GET,
  }),
  putRegistryTabs: ({ projectName, tabs }: PutRegistryTabsPayload) => ({
    url: `${governanceBaseUrl}/projects/${projectName}/models/tabs`,
    method: HttpMethods.PUT,
    body: tabs,
  }),
}
