import { Endpoints, HttpMethods } from 'consts'

import { ListEntry, ListBase } from '../lists.types'

const api = {
  getLists: () => ({
    url: `${Endpoints.PLATFORM}/lists`,
    method: HttpMethods.GET,
  }),
  getListsByProjectName: (projectName: string) => ({
    url: `${Endpoints.PLATFORM}/projects/${projectName}/lists`,
    method: HttpMethods.GET,
  }),
  postList: (body: ListBase) => ({
    url: `${Endpoints.PLATFORM}/lists`,
    method: HttpMethods.POST,
    body,
  }),
  putList: (list: ListBase) => ({
    url: `${Endpoints.PLATFORM}/lists/${list.id}`,
    method: HttpMethods.PUT,
    body: list,
  }),
  getList: (listId: string) => ({
    url: `${Endpoints.PLATFORM}/lists/${listId}`,
    method: HttpMethods.GET,
  }),
  getListByProjectName: (projectName: string, listId: string) => ({
    url: `${Endpoints.PLATFORM}/projects/${projectName}/lists/${listId}`,
    method: HttpMethods.GET,
  }),
  postListEntry: (listId: string, entry: ListEntry) => ({
    url: `${Endpoints.PLATFORM}/lists/${listId}/entry`,
    method: HttpMethods.POST,
    body: entry,
  }),
  putListEntry: (listId: string, entryId: string, entry: ListEntry) => ({
    url: `${Endpoints.PLATFORM}/lists/${listId}/entry/${entryId}`,
    method: HttpMethods.PUT,
    body: entry,
  }),
}

export default api
