import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import Tabs, { Tab } from 'components/Tabs'

interface OwnProps {
  value: EditRegistryModelTabNames
  onChange: (value: EditRegistryModelTabNames) => void
  edit?: boolean
}

export enum EditRegistryModelTabNames {
  GENERAL = 'general',
  PROPERTIES = 'properties',
  ACHIEVEMENTS = 'achievements',
}

const EditRegistryModelTabs: React.FC<OwnProps> = ({ value, onChange, edit }) => {
  const [t] = useTranslation()

  return (
    <Tabs
      style={{ padding: '0 20px' }}
      value={value}
      onChange={(_, value) => onChange(value)}
      variant="scrollable"
      scrollButtons="auto"
    >
      {Object.values(EditRegistryModelTabNames).map((value) => (
        <Tab value={value} label={t(`models.tabs.${value}`)} data-e2e-tab={value} />
      ))}
    </Tabs>
  )
}

export default memo(EditRegistryModelTabs)
