import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { IconButton } from '@mui/material'

import { closeAllDialogs } from 'services/dialog/actions'

import { HelpIcon } from './HelpLink.style'

interface HelpLinkProps {
  helpSection: string
  closeAllDialogs: () => void
  mini?: boolean | 'insideNotification'
}

const actionCreators = {
  closeAllDialogs,
}

const enhance = connect(null, actionCreators)

const HelpLink: React.FC<HelpLinkProps> = ({ helpSection, closeAllDialogs, mini }) => (
  <IconButton
    // @ts-ignore
    component={Link}
    to={`/dashboard/userManual/${helpSection}`}
    onClick={() => closeAllDialogs()}
    size="large"
  >
    <HelpIcon mini={mini} />
  </IconButton>
)

export default enhance(HelpLink)
