import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import styled from 'styled-components'

import { ModelImage } from 'services/modelDeployments/models/models.types'

import EditRegistryModelTabs, { EditRegistryModelTabNames } from './EditRegistryModelTabs'
import { ModelSchemaNames } from './RegistryModelForm'
import RegistryModelFormAchievements from './RegistryModelFormAchievements'
import RegistryModelFormGeneral from './RegistryModelFormGeneral'
import RegistryModelFormProperties from './RegistryModelFormProperties'
import DialogActions from '../DialogActions/DialogActions'

import DialogContent from '../DialogContent'

const TabContent = styled.div`
  padding-top: 24px;
`

interface Props {
  id: string
  model: ModelImage
  projectName: string
  onDismiss: () => void
}

const EditRegistryModelForm: React.FC<Props> = ({ id, projectName, onDismiss, model }) => {
  const { t } = useTranslation()
  const { isValid } = useFormikContext<ModelSchemaNames>()
  const [activeTab, setActiveTab] = useState<EditRegistryModelTabNames>(EditRegistryModelTabNames.GENERAL)

  return (
    <>
      <EditRegistryModelTabs value={activeTab} onChange={setActiveTab} />
      <DialogContent>
        <TabContent>
          {activeTab === EditRegistryModelTabNames.GENERAL && (
            <RegistryModelFormGeneral edit projectName={projectName} id={id} />
          )}
          {activeTab === EditRegistryModelTabNames.PROPERTIES && (
            <RegistryModelFormProperties edit projectName={projectName} />
          )}
          {activeTab === EditRegistryModelTabNames.ACHIEVEMENTS && (
            <RegistryModelFormAchievements modelAchievements={model.achievements} />
          )}
        </TabContent>
      </DialogContent>
      <DialogActions
        id={`${id}DialogActions`}
        dismissLabel={t('cancel')}
        confirmLabel={t('save')}
        dismissButtonComponentProps={{
          onClick: onDismiss,
          id: `${id}Cancel`,
        }}
        confirmButtonComponentProps={{
          type: 'submit',
          id: `${id}Ok`,
          disabled: !isValid,
        }}
      />
    </>
  )
}

export default EditRegistryModelForm
