import React, { useState } from 'react'

import { useField } from 'formik'
import styled from 'styled-components'

import { Cancel, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Checkbox } from '@mui/material'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'

import CustomChip from 'components/InlineChips/Chip/Chip'
import { SelectOption } from 'components/Input/Select/Select'
import TextField from 'components/Input/TextField'

type PartialAutocompleteProps = Partial<Omit<AutocompleteProps<SelectOption, true, false, false>, 'options'>>

type Props = PartialAutocompleteProps & {
  name: string
  options: SelectOption[]
  label?: string
  helperText?: string
}

const Chip = styled(CustomChip)`
  height: 24px;
  margin: 2px;
`

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />

const GlobalListAutocompleteFormField: React.FC<Props> = ({
  name,
  label,
  helperText,
  options: propsOptions,
  ...props
}) => {
  const [field, meta, { setValue }] = useField<string[]>(name)
  const [options] = useState(propsOptions)

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: SelectOption[]) => {
    setValue(value.map((option) => option.value))
  }
  const value = field.value.map((value) => {
    const selectedOption = options.find((option) => option.value === value)

    if (selectedOption) {
      return selectedOption
    }
    return {
      value,
      label: value,
    }
  })

  return (
    <Autocomplete
      {...props}
      disableCloseOnSelect
      options={options}
      value={value}
      onChange={handleChange}
      multiple
      getOptionLabel={(option: SelectOption) => option.label}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          label={label}
          {...params}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error ? meta.error : helperText}
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <span {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              value={option.value}
              checked={selected}
            />
            {option.label}
          </span>
        )
      }}
      clearIcon={<Cancel />}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} />)
      }
    />
  )
}

export default GlobalListAutocompleteFormField
