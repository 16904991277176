import React, { memo } from 'react'

import styled from 'styled-components'

import Error from '@mui/icons-material/Error'
import { StepIcon as MUIStepIcon, StepIconProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'

import SuccessIcon from './icons/SuccessIcon'

const useStyles = makeStyles(
  createStyles({
    root: {
      width: 32,
      height: 32,
    },
    completed: {
      color: 'green',
    },
    active: {
      color: 'black',
    },
  }),
)

const StyledCheck = styled(SuccessIcon)`
  color: ${({ theme }) => theme.palette.custom.success.main};
  width: 32px;
  height: 32px;
`
const StyledError = styled(Error)`
  width: 32px;
  height: 32px;
`

const StepIcon: React.FC<StepIconProps> = ({ completed, error, icon, ...rest }) => {
  const classes = useStyles()
  const selectedIcon = error ? (
    <StyledError fontSize="large" color="error" />
  ) : completed ? (
    <StyledCheck fontSize="large" />
  ) : (
    icon
  )

  return (
    <MUIStepIcon
      classes={{ root: classes.root, completed: classes.completed, active: classes.active }}
      icon={selectedIcon}
      {...rest}
    />
  )
}

export default memo(StepIcon)
