import React, { memo, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Button } from '@mui/material'

import ImagePlaceHolder from 'assets/images/image-placeholder.svg'
import FileInput from 'components/Input/FileInput/FileInput'
import { purgeFromImageCache, useImageUrl } from 'hooks/useImageUrl'

import { Container, ControlContainer, ImageContainer, RequirementsText } from './LogoSelectorModel.styles'

interface LogoSelectorProps {
  url: string
  onChange: (value: File | null) => void
  setFieldError: (value: string) => void
  error?: string
  value?: File | null
  image: string
  setImage: (value: string) => void
  maxWidth?: number
  maxHeight?: number
}

const LogoSelector = ({
  value = null,
  onChange,
  setFieldError,
  error,
  image,
  setImage,
  url,
  maxWidth = 250,
  maxHeight = 250,
}: LogoSelectorProps) => {
  const [t] = useTranslation()
  const logoImg = useImageUrl(url)
  const [modified, setModified] = useState<boolean>(false)

  const validateImage = useCallback(
    (file: File | null, image: string) => {
      const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']
      const maxSize = 500 * 1024 // 500 kB

      if (!file?.type || (file?.type && SUPPORTED_FORMATS.indexOf(file?.type) === -1)) {
        onChange(null)
        setImage('')
        return setFieldError(t('models.fileSelector.errors.wrongFormat'))
      }

      const imageSrc = new Image()
      imageSrc.src = image
      if (file.size > maxSize || imageSrc.height > maxHeight || imageSrc.width > maxWidth) {
        onChange(null)
        setImage('')
        return setFieldError(t('models.fileSelector.errors.wrongSize', { maxWidth, maxHeight }))
      }
    },
    [maxHeight, maxWidth, onChange, setFieldError, setImage, t],
  )

  useEffect(() => {
    const renderer = new FileReader()

    renderer.onloadend = () => {
      setImage(renderer.result as string)
      validateImage(value, image)
    }
    if (value && typeof value !== 'string') renderer.readAsDataURL(value)
  }, [value, setImage, validateImage, image])

  const handleRemove = () => {
    onChange(null)
    setImage('')
    setModified(true)
    setFieldError('')
    purgeFromImageCache(url)
  }

  const handleChange = (file: File) => {
    onChange(file as File)
    purgeFromImageCache(url)
    setModified(true)
    setFieldError('')
    setImage('')
  }

  return (
    <Container>
      <ImageContainer
        alt="logo"
        src={modified ? (image && !error ? image : ImagePlaceHolder) : logoImg && !error ? logoImg : ImagePlaceHolder}
      />
      <ControlContainer>
        <div>
          <FileInput
            startIcon={<AddIcon />}
            inputButton
            onChange={(value) => handleChange(value as File)}
            label={t('pick')}
            id="file"
          />
          <Button
            style={{ marginLeft: '5px' }}
            startIcon={<DeleteIcon />}
            size="small"
            onClick={handleRemove}
            disabled={!value && !image && !logoImg}
            color="secondary"
          >
            {t('delete')}
          </Button>
        </div>
        <RequirementsText error={!!error}>
          {error ? error : t('models.fileSelector.requirements', { maxWidth, maxHeight })}
        </RequirementsText>
      </ControlContainer>
    </Container>
  )
}

export default memo(LogoSelector)
