import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import InlineChips from 'components/InlineChips/InlineChips'
import { DialogTypes } from 'consts'
import useActionCreator from 'hooks/useActionCreator'
import { dialogOpened } from 'services/dialog/actions'

import { ChipsGroups } from 'components/DialogRoot/components/FormColumnsChips/FormColumnsChips.styles'

interface Props {
  id: string
  field?: string
  showLabel?: boolean
  className?: string
}

const AddTagsField: React.FC<Props> = ({ id, field, showLabel = true, className }) => {
  const { t } = useTranslation()
  const openDialog = useActionCreator(dialogOpened)
  const { setFieldValue, getFieldProps } = useFormikContext<any>()
  const fieldName = field ? field : 'tags'
  const tags = getFieldProps(fieldName).value || []

  const handleSelect = (values: string[]) => {
    setFieldValue(fieldName, values)
  }

  const handleDelete = ({ id }: Props) => {
    setFieldValue(
      fieldName,
      tags.filter((tag: string) => tag !== id),
    )
  }

  const openAddDialog = () =>
    openDialog(DialogTypes.ADD_TAGS, {
      id,
      tags,
      onSelect: handleSelect,
    })

  return (
    <ChipsGroups className={className}>
      <InlineChips
        id={id}
        label={showLabel ? `${t('tags')}:` : ''}
        chips={tags ? tags.map((tag: string) => ({ label: tag, id: tag })) : []}
        onAdd={openAddDialog}
        onDelete={handleDelete}
      />
    </ChipsGroups>
  )
}

export default memo(AddTagsField)
