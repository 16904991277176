import React, { FC, memo } from 'react'

import { Grid } from '@mui/material'

import ConfirmButton from './ConfirmButton'
import { CustomButtonProps } from './DialogActions.types'
import DismissButton from './DismissButton'

import { StyledMuiDialogActions } from './DialogActions.styles'

interface Props {
  id: string
  dismissLabel?: string
  confirmLabel?: string
  dismissButtonComponentProps?: CustomButtonProps
  confirmButtonComponentProps?: CustomButtonProps
  className?: string
  singleAction?: boolean
  leftActions?: FC<any>
}

const DialogActions: React.FC<Props> = ({
  id,
  dismissLabel = '',
  confirmLabel = '',
  dismissButtonComponentProps = {},
  confirmButtonComponentProps = {},
  className,
  singleAction,
  leftActions,
}) => {
  const LeftActions = leftActions

  return (
    <StyledMuiDialogActions className={className}>
      <Grid container justifyContent="space-between">
        <Grid item>{LeftActions && <LeftActions />}</Grid>
        <Grid item>
          {singleAction ? null : (
            <DismissButton id={id} label={dismissLabel} buttonProps={dismissButtonComponentProps} />
          )}
          <ConfirmButton id={id} label={confirmLabel} buttonProps={confirmButtonComponentProps} />
        </Grid>
      </Grid>
    </StyledMuiDialogActions>
  )
}

export default memo(DialogActions)
