import { partial, isPlainObject } from 'lodash'

import { trackEvent } from './tracker'

const stringify = (data: any) => {
  try {
    return isPlainObject(data) ? JSON.stringify(data) : `${data}`
  } catch {
    return ''
  }
}

const ui = partial(trackEvent, 'UI')

export const sidebarMiniToggled = (isMini: boolean) => ui('Sidebar mini toggled', `${isMini}`)
export const sidebarSubmenuToggled = (name: string, isOpen: boolean) =>
  ui(`Sidebar submenu ${isOpen ? 'opened' : 'closed'}`, name)
export const projectDropdownOpen = (name: string) => ui('Project dropdown opened', name)
export const headerDropdownOpen = (name: string) => ui('Header dropdown opened', name)
export const logout = () => ui('Logout')

const manage = partial(trackEvent, 'Manage dialog')

export const manageTableItemDetailsOpened = (tab: string, itemId: string) =>
  manage(`Manage ${tab} item details opened`, itemId)
export const manageTableItemsSelected = (tab: string, items: string[] = []) =>
  manage(`Manage ${tab} items selected`, stringify(items))

const project = partial(trackEvent, 'Project')

export const createProjectRequested = (projectName: string) => project('Create requested', projectName)
export const deleteProjectRequested = (projectName: string) => project('Delete requested', projectName)
export const addUserToProjectRequested = (projectName: string, userName: string) =>
  project(`Add user to "${projectName}" requested`, userName)
export const removeUserFromProjectRequested = (projectName: string, userName: string) =>
  project(`Remove user from "${projectName}" requested`, userName)

const workspace = partial(trackEvent, 'Workspace')

export const activateWorkspaceRequested = (projectName: string) => workspace('Activate requested', projectName)
export const deactivateWorkspaceRequested = (projectName: string) => workspace('Deactivate requested', projectName)

const user = partial(trackEvent, 'User')

export const createUserRequested = (userName: string) => user('Create requested', userName)
export const deleteUserRequested = (userName: string) => user('Delete requested', userName)
export const editUserRequested = (userName: string) => user('Edit requested', userName)

const platform = partial(trackEvent, 'Platform')

export const platformQuotaEditRequested = (values: any) =>
  platform('Edit platform resources requested', stringify(values))
export const projectQuotaEditRequested = (values: any) => platform('Edit project quota requested', stringify(values))
export const nodeQuotaEditRequested = (values: any) => platform('Edit node quota requested', stringify(values))

export const errorNotification = (title: string, text: string) =>
  trackEvent('Error', 'Notification appears', `${title}: ${text}`)
