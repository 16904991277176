import React, { FC, useEffect, useState } from 'react'

import { empty } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import GlobalListAutocompleteFormField from 'components/DialogRoot/components/RegistryModelDialog/components/GlobalListAutocompleteFormField'
import SelectFormField from 'components/Form/SelectFormField'
import { ListEntry, ListBase, List } from 'services/lists/lists.types'
import api from 'services/lists/utils/lists.api'
import ajax from 'utils/ajax'

import { ListTypes } from '../../Lists/components/ListForm'

interface UserSelectFieldProps {
  id: string
  name: string
  label: string
  projectName: string
  listType: ListTypes
  required?: boolean
  multiselect?: boolean
}
export const GlobalListSelectField: FC<UserSelectFieldProps> = ({
  name,
  id,
  label,
  listType,
  required,
  multiselect,
  projectName,
}) => {
  const [entries, setEntries] = useState<ListEntry[]>([])

  useEffect(() => {
    const stream = ajax(api.getListsByProjectName(projectName))
      .pipe(
        switchMap(({ response }: { response: ListBase[] }) => {
          const list = response?.find(({ type }) => type === listType)

          if (list) return ajax(api.getListByProjectName(projectName, list.id))
          return empty()
        }),
      )
      .subscribe(
        ({ response }: { response: List }) => {
          setEntries(response?.entries?.filter(({ active }) => active) || [])
        },
        (error) => {
          console.error(error)
        },
      )

    return () => {
      stream.unsubscribe()
    }
  }, [listType, projectName, setEntries])

  const options = (entries || []).map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  if (options.length === 0) {
    return null
  }

  return multiselect ? (
    <GlobalListAutocompleteFormField id={id} name={name} label={label} options={options!} />
  ) : (
    <SelectFormField id={id} name={name} label={label} options={options!} required={required} />
  )
}
export default GlobalListSelectField
