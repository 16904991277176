import { Id } from 'react-toastify'

import { filter, difference, some } from 'lodash'
import { createSelector } from 'reselect'

import { RootState } from 'store/root.reducer'

import { NotificationState } from './notifications.types'

const root = (state: RootState) => state.notifications
export const getNotificationsById = (state: RootState) => root(state).byId
export const getNotificationsIds = (state: RootState) => root(state).ids

export const getNotifications = createSelector(getNotificationsById, getNotificationsIds, (byId, ids) =>
  ids.map((id) => byId[id]),
)

export const isPendingNotificationExists = createSelector(getNotifications, (notifications) => {
  return some(notifications, { state: NotificationState.PENDING })
})

export const getPendingNotifications = createSelector(getNotifications, (notifications) => {
  return filter(notifications, { state: NotificationState.PENDING })
})

export const getPendingNotificationsByPath = createSelector(
  getPendingNotifications,
  (_: RootState, path: string | null) => path,
  (notifications, path) => (notifications.length && path ? filter(notifications, { path }) : []),
)

export const getPendingNotificationsNotInLocation = createSelector(
  getPendingNotifications,
  (state: RootState, path: string | null) => getPendingNotificationsByPath(state, path),
  (pendingNotifications, pendingNotificationsByPath) => difference(pendingNotifications, pendingNotificationsByPath),
)

export const getNotificationById = createSelector(
  getNotificationsById,
  (_: RootState, id: Id) => id,
  (byId, id) => byId[id],
)
