import React from 'react'
import { useTranslation } from 'react-i18next'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, InputAdornment, TextField as MuiTextField, TextFieldProps as MUITextFieldProps } from '@mui/material'

export type TextFieldProps = Omit<MUITextFieldProps, 'variant' | 'error'> & {
  error?: any
}
// @todo: use TextFieldProps when this is solved: https://github.com/i18next/react-i18next/issues/1543
const TextField: React.FC<any> = ({ value = null, required, helperText: message, error, onChange, type, ...props }) => {
  const [t] = useTranslation()
  const helperText = message || (required ? `* ${t('validation.required')}` : null)
  const maxLength = props.inputProps?.maxLength

  return (
    <MuiTextField
      variant="outlined"
      value={value}
      required={required}
      helperText={
        maxLength && !props.disabled ? (
          <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{helperText}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{`${value.length}/${maxLength}`}</span>
          </Box>
        ) : (
          helperText
        )
      }
      InputProps={{
        endAdornment: maxLength && value.length === maxLength && !props.disabled && (
          <InputAdornment sx={{ margin: 0 }} position="end">
            <WarningAmberIcon />
          </InputAdornment>
        ),
      }}
      error={!!error}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (type === 'number') {
          const target = event.target
          const value = parseFloat(target.value)

          onChange({
            type: 'change',
            target: {
              name: target.name,
              value: isNaN(value) ? undefined : value,
            },
          })
          return
        }
        onChange(event)
      }}
      type={type}
      {...props}
      size="small"
    />
  )
}

export default TextField
