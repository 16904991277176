import compose from 'lodash/fp/compose'
import filter from 'lodash/fp/filter'
import { createSelector } from 'reselect'

import { TypesOfIDE } from 'services/api/workspaces/workspace.types'
import { ImageCategory } from 'services/registryImages/registryImages.types'
import { RootState } from 'store/root.reducer'

import { PlatformImage } from './platformImages.types'

const root = (state: RootState) => state.platformImages
const platformImagesById = (state: RootState) => root(state).byId
const platformImagesIds = (state: RootState) => root(state).ids

export const getPlatformImages = createSelector(platformImagesById, platformImagesIds, (byId, ids) =>
  ids.map((id) => byId[id]),
)

export const getPlatformImageById = createSelector(
  platformImagesById,
  (_: RootState, id: string) => id,
  (byId, id) => byId[id],
)

export const getWorkspaceImagesFromPlatform = createSelector(
  getPlatformImages,
  compose(filter((image: PlatformImage) => Object.values(TypesOfIDE).includes(image.type as any))),
)

export const getPipelineImagesFromPlatform = createSelector(
  getPlatformImages,
  compose(filter((image: PlatformImage) => image.type === ImageCategory.PIPELINES)),
)
