import React from 'react'

import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Typography from '@mui/material/Typography'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.lightGrey7};
`

const ModelAchievementsListPlaceholder = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <StyledTypography variant="subtitle1">{t('models.achievementsPlaceholderTitle')}</StyledTypography>
      <StyledTypography variant="subtitle2">{t('models.achievementsPlaceholderSubtitle')}</StyledTypography>
    </Container>
  )
}

export default ModelAchievementsListPlaceholder
