import React, { memo } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import { pickBy } from 'lodash'
import { SshKey } from 'types'

import { Cancel } from '@mui/icons-material'
import { Chip as MuiChip, styled } from '@mui/material'
import { ChipProps } from '@mui/material/Chip'

import Avatar from 'components/Avatar'
import Image from 'components/Image'

const darkColors = [
  '#928888',
  '#D44FBE',
  '#13A46A',
  '#468CCD',
  '#6D4DCC',
  '#a28089',
  '#8458B3',
  '#ff1d58',
  '#ff1e00',
  '#59ce8f',
  '#ff0028',
  '#657a00',
  '#2d545e',
  '#7c677f',
]

const DeleteIcon = styled(Cancel)`
  height: 16px;
  width: 16px;
  fill: rgba(0, 0, 0, 0.12);

  &:hover {
    fill: #d00026;
  }
`
export type ChipColor = 'green' | 'red' | 'lightGreen' | 'lightRed' | 'orange' | 'black' | 'default'

type StyledChipProps = {
  $chipColor: ChipColor
  noMargin?: boolean
  $bgColor?: string
} & ChipProps

const getColor = (color: string) => {
  if (['green', 'red', 'black', 'orange', ...darkColors].includes(color)) {
    return '#ffffff'
  }
  return 'rgba(0,0,0,0.87)'
}

const InlineChipWrapper = styled('div')`
  display: inline-block; // wrapper to fix issue with 'display:inline-flex' in jspdf printing
`

const Chip = styled(MuiChip)<StyledChipProps>`
  margin: ${({ noMargin }) => (noMargin ? '0' : '8px')};
  ${({
    $chipColor,
    variant,
    theme: {
      palette: { chips },
    },
  }) => (variant !== 'outlined' ? `background-color: ${chips[$chipColor]}` : 'background-color: transparent')};
  ${({ $bgColor }) => $bgColor && `background-color:  ${$bgColor}`};
  display: flex;

  &:hover {
    ${({
      $chipColor,
      onClick,
      variant,
      theme: {
        palette: { chips },
      },
    }) =>
      variant !== 'outlined'
        ? `background-color: ${onClick ? chips.hoverColors[$chipColor] : chips[$chipColor]}`
        : null};
  }

  & .MuiChip-icon {
    margin-left: 9px;
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }

  & .MuiChip-label {
    ${({ icon }) => (Boolean(icon) ? 'padding-left: 0' : null)}
  }

  & .MuiTypography-root {
    ${({ $chipColor, variant }) => (variant !== 'outlined' ? `color: ${getColor($chipColor)}` : null)};
    ${({ $bgColor }) => $bgColor && `color: ${getColor($bgColor)}`};
  }
`

const StyledAvatar = styled(Avatar)`
  margin-left: 8px;
`

interface Props extends Omit<ChipProps, 'avatar' | 'ref' | 'innerRef' | 'bgColor'> {
  avatar?: string | string[]
  onDelete?: (props: any) => void
  sshKey?: SshKey
  bgColor?: string
  chipColor?: ChipColor
  noMargin?: boolean
}

const getAvatar = (avatar?: string | string[]): React.ReactElement | undefined => {
  return avatar ? (
    // @ts-ignore
    <StyledAvatar>
      <Image altSize="35px" src={avatar as string} />
    </StyledAvatar>
  ) : undefined
}

const ChipWithoutPopover: React.FC<Props> = ({
  avatar,
  label,
  onDelete,
  sshKey = null,
  chipColor = 'default',
  bgColor = '',
  noMargin,
  ...props
}) => {
  const validDOMProps = pickBy(props, (_v, key) => isPropValid(key))
  return (
    <InlineChipWrapper>
      <Chip
        {...props}
        label={label}
        avatar={getAvatar(avatar)}
        onDelete={onDelete ? () => onDelete({ ...props, sshKey, label }) : undefined}
        deleteIcon={onDelete ? <DeleteIcon /> : undefined}
        $chipColor={chipColor}
        $bgColor={bgColor}
        noMargin={noMargin}
        {...validDOMProps}
      />
    </InlineChipWrapper>
  )
}

export default memo(ChipWithoutPopover)
