import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomButtonProps } from './DialogActions.types'

import { NoMarginButton } from './DialogActions.styles'

interface Props {
  id: string
  label?: string
  buttonProps?: CustomButtonProps
}

const ConfirmButton: React.FC<Props> = ({ id, label, buttonProps = {} }) => {
  const [t] = useTranslation()
  return (
    <NoMarginButton
      type="submit"
      id={`${id}Ok`}
      data-cy="confirmationOk"
      color="secondary"
      variant="contained"
      {...buttonProps}
    >
      {label || t('ok')}
    </NoMarginButton>
  )
}

export default memo(ConfirmButton)
