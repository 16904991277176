import * as React from 'react'

const SuccessIcon = (props: any) => (
  <svg width={33} height={32} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} width={32} height={32} rx={16} fill="#007FC7" />
    <g clipPath="url(#clip0_699_1390)">
      <path
        d="M22.465 10.705L21.055 9.29504L14.715 15.635L16.125 17.045L22.465 10.705ZM26.705 9.29504L16.125 19.875L11.945 15.705L10.535 17.115L16.125 22.705L28.125 10.705L26.705 9.29504ZM4.875 17.115L10.465 22.705L11.875 21.295L6.295 15.705L4.875 17.115Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_699_1390">
        <rect width={24} height={24} fill="white" transform="translate(4.5 4)" />
      </clipPath>
    </defs>
  </svg>
)
export default SuccessIcon
