import { RegistryModelAchievement } from 'services/modelDeployments/models/models.types'

import { ModelAchievements } from './RegistryModelForm'
import { ModelAchievementsTypes } from './RegistryModelFormAchievements'

export const getModelAchievementsFromDto = (achievements?: RegistryModelAchievement[]) => {
  const modelAchievements: ModelAchievements = {
    [ModelAchievementsTypes.OBTAINED]: [],
    [ModelAchievementsTypes.RETRY]: [],
  }
  if (!achievements) return modelAchievements

  for (const achievement of achievements) {
    const { achId, status } = achievement
    if (status === ModelAchievementsTypes.OBTAINED) {
      modelAchievements[ModelAchievementsTypes.OBTAINED] = [
        ...modelAchievements[ModelAchievementsTypes.OBTAINED],
        achId,
      ]
    } else if (status === ModelAchievementsTypes.RETRY) {
      modelAchievements[ModelAchievementsTypes.RETRY] = [...modelAchievements[ModelAchievementsTypes.RETRY], achId]
    }
  }
  return modelAchievements
}

export const getDtoFromModelAchievements = (achievements: ModelAchievements) => {
  const list: RegistryModelAchievement[] = []
  Object.entries(achievements).map(([status, achievementsList]) => {
    achievementsList.forEach((achId) => {
      if (Object.values(ModelAchievementsTypes).includes(status as ModelAchievementsTypes)) {
        list.push({ achId, status })
      }
    })
  })
  return list
}
