import styled from 'styled-components'

import { DialogContent as MuiDialogContent } from '@mui/material'

const DialogContent = styled(MuiDialogContent)`
  padding: 18px 24px 24px;
`

export const FlexDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

export const DialogContentNoOverflowX = styled(DialogContent)`
  overflow-x: hidden;
`

export const DialogContentNoOverflow = styled(DialogContent)`
  overflow: hidden;
`

export const StyledDialogContent = styled(DialogContentNoOverflow)`
  &:first-child {
    padding-top: 0;
  }
`

export const DialogContentNoPadding = styled(MuiDialogContent)`
  padding: 0;
  display: flex;
`

export default DialogContent
