export interface RegistryImage {
  id: string
  installed: boolean
  libImageName: string
  libMountDir: string
  libNfsDir: string
  name: string
  pullUrl: string
  type: string
  withGpu: boolean
  custom: boolean
  deprecated: boolean
}

export enum FilterLabels {
  PIPELINES = 'Pipelines',
  WORKSPACES = 'Workspaces',
  MODELS = 'Production models',
  APPLICATIONS = 'Applications',
}

export enum ImageCategory {
  PIPELINES = 'pipelines',
  WORKSPACES = 'workspaces',
  MODELS = 'models',
  APPLICATIONS = 'applications',
}
