import { CertificateReport } from 'services/certificates/certificates.types'

export enum DeploymentState {
  CREATE = 'creating',
  UPDATE = 'updating',
  DELETING = 'deleting',
  STOPPING = 'stopping',
  READY = 'ready',
  RECOVER = 'recovering',
  DELETED = 'deleted',
  INVALID = 'invalid',
  RETRY = 'retry',
  HEALING = 'healing',
  PREPARE = 'preparing',
  PREPARED = 'prepared',
}

export enum ScalingStrategy {
  cpu = 'cpu',
  requests = 'requests',
  static = 'static',
  redis = 'redis',
  manual = 'manual',
}

export enum ImageType {
  MODEL = 'model',
  FASTAPI = 'fastapi',
}

export interface DeploymentResources {
  gpu?: number
  cpu?: number
  memory?: number
}

export type ScalingConfig =
  | {
      scalingCPUThreshold: number
      scalingReqThreshold?: number
      scalingReqTimeFrameSec?: number
      scalingStrategy: ScalingStrategy.cpu
      scalingRedisListLength?: number
      scalingPollingInterval?: number
      maxReplicas: number
      minReplicas: number
    }
  | {
      scalingCPUThreshold?: number
      scalingReqThreshold: number
      scalingReqTimeFrameSec: number
      scalingStrategy: ScalingStrategy.requests
      scalingRedisListLength?: number
      scalingPollingInterval?: number
      maxReplicas: number
      minReplicas: number
    }
  | {
      scalingCPUThreshold?: number
      scalingReqThreshold: number
      scalingReqTimeFrameSec: number
      scalingStrategy: ScalingStrategy.redis
      scalingRedisListLength?: number
      scalingPollingInterval?: number
      maxReplicas: number
      minReplicas: number
    }
  | {
      scalingCPUThreshold?: number
      scalingReqThreshold?: number
      scalingReqTimeFrameSec?: number
      scalingStrategy: ScalingStrategy.static
      scalingRedisListLength?: number
      scalingPollingInterval?: number
      maxReplicas: number
      minReplicas: number
    }
  | {
      scalingCPUThreshold?: number
      scalingReqThreshold?: number
      scalingReqTimeFrameSec?: number
      scalingStrategy: ScalingStrategy.manual
      scalingRedisListLength?: number
      scalingPollingInterval: number
      maxReplicas: number
      minReplicas: number
    }

export interface Deployment {
  id: string
  projectName: string
  deploymentName: string
  imageVersion: string
  monitoring: string
  imageTag: string
  createdAt: string
  createdBy: string
  modelName: string
  state: DeploymentState
  deploymentUrl: string
  podName: string
  namespace: string
  grafanaUrl: string
  lastUserMsg: string
  lastErrorMsg: string
  certificationResults: CertificateReport[] | null
  imageName?: string
  imageType: ImageType
  statuses?: Record<string, string>
  celeryModel: boolean
  availableModelReplicas: number
  availableRedisReplicas: number
  availableWorkerReplicas: number
  deployments: K8SDeployment[]
  baseImage: string
  additionalBaseGpuImage: string
  config: string
}

export interface K8SDeployment {
  objectId: string
  resources: DeploymentResources
  scalingConfig: ScalingConfig
  type: 'model' | 'redis' | 'worker'
}

export interface DeploymentEvent {
  id: string
  project: string
  state: DeploymentState
  statuses: Record<string, string>
  user_msg?: string
  error_msg?: string
  type?: string
}

export interface LogEvent {
  containerName?: string
  timestamp: string
  message: string
}

export interface DeploymentNodeResources {
  cpu: number
  memory: number
  maxReplicas: string | number
  minReplicas: string | number
}

export enum DeploymentScope {
  modelDeployment = 'modelK8SDeployment',
  redisDeployment = 'redisK8SDeployment',
  workerDeployment = 'workerK8SDeployment',
}
