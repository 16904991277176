import React, { memo, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useFormikContext } from 'formik'

import OptionalLabel from 'components/Stepper/OptionalLabel'
import Step from 'components/Stepper/Step'
import StepIcon from 'components/Stepper/StepIcon'
import StepLabel from 'components/Stepper/StepLabel'
import Stepper from 'components/Stepper/Stepper'

interface Props {
  activeStep: number
  steps: any[]
}

const CreateRegistryModelStepper: React.FC<Props> = ({ activeStep, steps }) => {
  const { t } = useTranslation()

  const { validateForm } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [activeStep, validateForm])

  return (
    <Stepper style={{ marginBottom: '20px' }} activeStep={activeStep} alternativeLabel>
      {steps.map(({ label }, i) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={StepIcon}
            error={false}
            data-cy={`stepper-label-${i}`}
            optional={<OptionalLabel text={`${t('step')} ${i + 1}`} />}
            active={activeStep === i}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default memo(CreateRegistryModelStepper)
