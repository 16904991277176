enum Path {
  dashboard = 'dashboard',
  events = 'events',
  listView = 'listView',
  projects = 'projects',
}

export enum MainRoutes {
  CRITICAL_ERROR = '/error',
  AUTHENTICATING = '/authenticating',
  BEFORE_RELOAD_KEY = 'routeBeforeReload',
  DASHBOARD = 'dashboard',
  DASHBOARD_PROJECTS = '/dashboard/projects',
  REALM = 'realm/:realmName',
}

export enum RouteIds {
  PROJECT_DESCRIPTION = 'projectDescription',
  ONBOARDING = 'projectOnboarding',
  ONBOARDING_ASSESSMENT = 'projectOnboardingAssessment',
  ONBOARDING_ASSESSMENT_STAGE = 'projectOnboardingAssessmentStage',
}

export default Path
