import { Id } from 'react-toastify'

export interface PlatformNotification {
  id: Id
  text: string
  subText: any
  path: string
  state: NotificationState
  bodyType: NotificationBodyType
}

export enum NotificationState {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum NotificationBodyType {
  EXPANDABLE = 'EXPANDABLE',
  NORMAL = 'NORMAL',
}
