import React from 'react'

import isNumber from 'lodash/isNumber'
import styled from 'styled-components'

import Avatar, { AvatarProps } from '@mui/material/Avatar'

export interface StyledAvatarProps {
  size?: number | string
  warning?: boolean
}

type Props = StyledAvatarProps & AvatarProps

const StyledAvatar = styled(({ size, warning, ...rest }: Props) => <Avatar {...rest} />)`
  width: ${({ size }) => (isNumber(size) ? `${size}px` : size)};
  height: ${({ size }) => (isNumber(size) ? `${size}px` : size)};
  background-color: ${({ warning, theme }) =>
    warning ? theme.palette.custom.status.stopped.background : theme.palette.grey[400]};
  img {
    height: 100%;
  }
`

StyledAvatar.defaultProps = {
  size: 'auto',
}

export default StyledAvatar
