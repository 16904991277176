import styled from 'styled-components'

import { StepLabel } from '@mui/material'

const StyledStepLabel = styled(StepLabel)<{ active: boolean }>`
  .MuiStepLabel-labelContainer {
    max-width: 200px;
    .MuiStepLabel-label {
      color: black;
      font-weight: ${({ active }) => (active ? '600' : '500')};
      margin-top: 8px;
    }
  }
`

export default StyledStepLabel
