import React, { memo } from 'react'

import { ChipT } from 'types'

import Chip from './Chip/Chip'

interface Props {
  chips: ChipT[]
  onDelete?: (item: any) => void
  popoverContentRenderer?: React.ComponentType<any>
}

const ChipsList: React.FC<Props> = ({ chips = [], onDelete, popoverContentRenderer }) => {
  return (
    <>
      {chips.map(({ label, id, ...chipProps }) => (
        <Chip
          data-e2e={`chip-${label}`}
          key={id || label}
          label={label}
          popoverContentRenderer={popoverContentRenderer}
          onDelete={onDelete}
          id={id}
          {...chipProps}
        />
      ))}
    </>
  )
}

export default memo(ChipsList)
