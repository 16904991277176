import React, { memo, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Field, FieldProps, useFormikContext } from 'formik'

import styled from 'styled-components'

import { Grid } from '@mui/material'

import TextFormField from 'components/Form/TextFormField'
import ResourcesTitle from 'components/FormProject/components/ResourcesTitle'
import AddTagsField from 'components/Input/AddTagsField/AddTagsField'
import useActionCreator from 'hooks/useActionCreator'
import { getModels } from 'services/modelDeployments/models/models.selectors'
import { ModelImage } from 'services/modelDeployments/models/models.types'
import { getModelLogoUrl } from 'services/modelDeployments/models/utils/models.api'
import { platformImagesFetchRequest } from 'services/platformImages/platformImages.actions'
import { ImageCategory } from 'services/registryImages/registryImages.types'
import { mapDisplayNameToName } from 'utils/formatters'

import { ModelSchemaNames, RegistryModelFormValues } from './RegistryModelForm'
import GlobalListSelectField from './components/GlobalListSelectField'
import LogoSelectorModel from './components/LogoSelector'

import { UserSelectField } from './components/UserSelectField'

import { ListTypes } from '../Lists/components/ListForm'

import { GridRow, RowsSectionWrapper } from '../FormColumnsChips/FormColumnsChips.styles'

const StyledResourcesTitle = styled(ResourcesTitle)`
  padding: 0;
`

const StyledAddTagsField = styled(AddTagsField)`
  margin-top: 0;
`

interface Props {
  projectName: string
  id?: string
  edit?: boolean
}

const RegistryModelFormGeneral: React.FC<Props> = ({ projectName, edit, id }) => {
  const [t] = useTranslation()
  const models = useSelector(getModels)
  const [image, setImage] = useState<string>('')
  const [logoError, setLogoError] = useState<string>('')
  const fetchPlatformImages = useActionCreator(platformImagesFetchRequest)

  const { values, setFieldValue } = useFormikContext<RegistryModelFormValues>()

  useEffect(() => {
    fetchPlatformImages([ImageCategory.MODELS])
  }, [fetchPlatformImages])

  const validateVersion = (value: string) => {
    if (edit) return
    const existingModels = models.filter((item: ModelImage) => item.name === values.name)
    if (existingModels.length === 0) {
      return
    }
    if (existingModels.some(({ version }) => version === value)) {
      return t('errors.models.modelVersionExists')
    }
  }

  const handleDisplayNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value || ''

      !edit && setFieldValue(ModelSchemaNames.name, mapDisplayNameToName(value).substring(0, 10))
      setFieldValue(ModelSchemaNames.displayName, value)
    },
    [edit, setFieldValue],
  )

  return (
    <RowsSectionWrapper container>
      <GridRow item container xs={12} spacing={3}>
        <Grid item container xs={8} spacing={3}>
          <Grid item xs={6}>
            <TextFormField
              autoFocus
              id="nameFormField"
              name={ModelSchemaNames.displayName}
              onChange={handleDisplayNameChange}
              label={t('inputs.modelName')}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field validate={validateVersion} name={ModelSchemaNames.version}>
              {({ field, meta }: FieldProps) => (
                <TextFormField
                  {...field}
                  required
                  disabled={edit}
                  id="versionFormField"
                  name={ModelSchemaNames.version}
                  label={t('version')}
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <TextFormField
              id="businessOwnerFormField"
              name={ModelSchemaNames.businessOwner}
              label={t('inputs.businessOwner')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFormField id="techOwnerField" name={ModelSchemaNames.techOwner} label={t('inputs.techOwner')} />
          </Grid>
          <Grid item xs={6}>
            <UserSelectField
              id="responsibleFormField"
              name={ModelSchemaNames.responsible}
              label={t('inputs.responsible')}
              projectName={projectName}
            />
          </Grid>
          <Grid item xs={6}>
            <GlobalListSelectField
              multiselect
              id="industryField"
              projectName={projectName}
              name={ModelSchemaNames.industry}
              label={t('inputs.industry')}
              listType={ListTypes.industry}
            />
          </Grid>
          <Grid item xs={12}>
            <GlobalListSelectField
              multiselect
              id="countryField"
              projectName={projectName}
              name={ModelSchemaNames.countryJurisdiction}
              label={t('inputs.countryJurisdiction')}
              listType={ListTypes.country}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Field name={ModelSchemaNames.logo}>
            {({ field }: FieldProps) => (
              <LogoSelectorModel
                url={id ? getModelLogoUrl(projectName, id) : ''}
                onChange={(value) => setFieldValue(ModelSchemaNames.logo, value)}
                setFieldError={(value) => setLogoError(value)}
                value={field.value}
                error={logoError}
                image={image}
                setImage={setImage}
                maxWidth={500}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={8}>
          <TextFormField
            rows={4}
            multiline={true}
            name={ModelSchemaNames.description}
            label={t('inputs.modelDescription')}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledResourcesTitle>{t('tags')}</StyledResourcesTitle>
          <StyledAddTagsField id="model-registry-tags" />
        </Grid>
      </GridRow>
    </RowsSectionWrapper>
  )
}

export default memo(RegistryModelFormGeneral)
