import React from 'react'

import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

import styled from 'styled-components'

import DeleteIcon from '@mui/icons-material/DeleteOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'

import { getAchievementByID } from 'services/achievements/achievements.selectors'
import { RootState } from 'store/root.reducer'
import formatDateToAgo from 'utils/formatters/formatDateToAgo'

import { StyledIconButton } from 'components/Assessments/Builder/components/Page.styles'

import AchievementPicture from './AchievementPicture'

import { ModelAchievementsTypes } from '../RegistryModelFormAchievements'

const Title = styled(Typography)`
  font-weight: 600;
  width: 200px;
  text-overflow: 'ellipsis';
`

const Subtitle = styled(Typography)`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.3px;
`

const AchievementContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const StyledAccordion = styled(Accordion)<{ $type: string }>`
  background-color: ${({ theme, $type }) => theme.palette.custom.achievements[$type]};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.55);
`

interface Props {
  type: string
  achievementID: string
  addedAt?: string
  expanded: string | false
  setExpanded: (id: string | false) => void
  onDelete?: (e: React.MouseEvent<HTMLButtonElement>, id: string, type: ModelAchievementsTypes) => void
}

const AchievementAccordionItem: React.FC<Props> = ({
  type,
  achievementID,
  addedAt,
  expanded,
  setExpanded,
  onDelete,
}) => {
  const { t } = useTranslation()
  const achievement = useSelector((state: RootState) => getAchievementByID(state, achievementID))

  if (!achievement) return null

  const { id, name, priority, description } = achievement

  const handleChange = (panel: string) => {
    setExpanded(expanded === id ? false : panel)
  }

  return (
    <StyledAccordion $type={type} key={id} expanded={expanded === id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => handleChange(id)} />}>
        <AchievementContainer>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={2}>
              <AchievementPicture id={id} />
            </Grid>
            <Grid item xs={10}>
              <Title noWrap variant="subtitle1">
                {name}
              </Title>
              {addedAt && <Subtitle variant="caption">{formatDateToAgo(addedAt)} / </Subtitle>}
              <Subtitle variant="caption">
                {t('achievements.priority')}: {t(`achievements.priorityNames.${priority}`)}
              </Subtitle>
            </Grid>
          </Grid>
          {onDelete && (
            <StyledIconButton
              onClick={(e) => onDelete(e, id, type as ModelAchievementsTypes)}
              data-cy={`delete-Achievement-${id}`}
            >
              <DeleteIcon />
            </StyledIconButton>
          )}
        </AchievementContainer>
      </AccordionSummary>
      <AccordionDetails>{description}</AccordionDetails>
    </StyledAccordion>
  )
}

export default AchievementAccordionItem
