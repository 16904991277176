import React, { memo, useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

import { Field, FieldProps, FormikProps, withFormik } from 'formik'

import i18n from 'i18n'
import getOr from 'lodash/fp/getOr'
import * as yup from 'yup'

import { Grid } from '@mui/material'

import DialogActions from 'components/DialogRoot/components/DialogActions/DialogActions'

import DialogContent from 'components/DialogRoot/components/DialogContent'
import TextFormField from 'components/Form/TextFormField'
import { FlexForm } from 'components/FormProject/components'
import Select from 'components/Input/Select/Select'
import { NO_SPECIAL_SYMBOLS_REGEXP } from 'consts'
import { getLists } from 'services/lists/lists.selectors'
import { ListBase } from 'services/lists/lists.types'
import generateUniqueName from 'utils/generateUniqueName'
import { sanitizeName } from 'utils/sanitizeName'

export enum ListTypes {
  country = 'country',
  industry = 'industry',
  riskCategories = 'risk_categories',
  modelType = 'model_type',
}

enum ListFormNames {
  name = 'name',
  id = 'id',
  type = 'type',
}

export interface ListFormValues {
  [ListFormNames.name]: string
  [ListFormNames.id]: string
  [ListFormNames.type]: string
}

const createValidationSchema = () =>
  yup.object<ListFormValues>({
    [ListFormNames.name]: yup
      .string()
      .required()
      .matches(NO_SPECIAL_SYMBOLS_REGEXP, { message: i18n.t('validation.onlyAlphanum') }),
    [ListFormNames.id]: yup.string().required(),
    [ListFormNames.type]: yup.string().required(),
  })

const getInitialValues = (initialValues?: ListFormValues): ListFormValues => ({
  [ListFormNames.name]: getOr('', 'name', initialValues),
  [ListFormNames.id]: getOr('', 'id', initialValues),
  [ListFormNames.type]: getOr('', 'type', initialValues),
})

const enhance = withFormik<OwnProps, ListFormValues>({
  enableReinitialize: true,
  validationSchema: createValidationSchema,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  },
  mapPropsToValues: ({ initialValues }) => getInitialValues(initialValues),
})

interface OwnProps {
  id: string
  edit?: boolean
  initialValues?: ListFormValues
  onSubmit: (values: ListFormValues) => void
  onClose: () => void
}

type Props = OwnProps & FormikProps<ListFormValues>

const ListForm: React.FC<Props> = ({ edit, touched, setFieldValue, onClose, dirty, isValid, id }) => {
  const { t } = useTranslation()
  const lists = useSelector(getLists)

  const typeOptions = useMemo(
    () => [
      {
        label: t(`lists.types.${ListTypes.country}`),
        value: ListTypes.country,
      },
      {
        label: t(`lists.types.${ListTypes.industry}`),
        value: ListTypes.industry,
      },
      {
        label: t(`lists.types.${ListTypes.riskCategories}`),
        value: ListTypes.riskCategories,
      },
      {
        label: t(`lists.types.${ListTypes.modelType}`),
        value: ListTypes.modelType,
      },
    ],
    [t],
  )

  const validateType = (value: string) => {
    if (edit) return
    const typeReserved = lists.some((item: ListBase) => item.type === value)
    if (typeReserved) return t('lists.errors.listExists', { type: t(`lists.types.${value}`) })
  }

  const handleNameChange = useCallback(
    (e: any) => {
      const { value } = e.target

      if (!edit) {
        setFieldValue(
          ListFormNames.id,
          generateUniqueName(sanitizeName(value), new Set([...lists.map(({ id }) => id)])),
        )
      }
      setFieldValue(ListFormNames.name, value)
    },
    [setFieldValue, edit, lists],
  )

  return (
    <FlexForm noValidate>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name={ListFormNames.name}>
              {({ field, meta }: FieldProps) => (
                <TextFormField
                  {...field}
                  autoFocus
                  required
                  id="nameFormField"
                  name={ListFormNames.name}
                  onChange={handleNameChange}
                  label={t('inputs.name')}
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name={ListFormNames.id}>
              {({ field, meta }: FieldProps) => (
                <TextFormField
                  {...field}
                  disabled
                  required
                  id="idFormField"
                  name={ListFormNames.id}
                  label={t('inputs.id')}
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error ? meta.error : ''}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name={ListFormNames.type} validate={validateType}>
              {({ field, meta }: FieldProps) => (
                <Select
                  {...field}
                  required
                  fullWidth
                  disabled={edit}
                  id="typeSelect"
                  options={typeOptions}
                  label={t('inputs.type')}
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        id={`${id}DialogActions`}
        confirmLabel={edit ? t('save') : t('create')}
        dismissLabel={t('cancel')}
        dismissButtonComponentProps={{
          onClick: onClose,
        }}
        confirmButtonComponentProps={{
          type: 'submit',
          id: `${id}Ok`,
          disabled: !dirty || !isValid,
        }}
      />
    </FlexForm>
  )
}

export default enhance(memo(ListForm))
