import React from 'react'

import { animated, useSpring } from 'react-spring'

import Collapse from '@mui/material/Collapse'
import { TransitionProps } from '@mui/material/transitions'

const Transition: React.FC<TransitionProps> = (props) => {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  })

  return (
    <animated.div style={style}>
      <Collapse {...(props as any)} />
    </animated.div>
  )
}

export default Transition
