import React, { memo } from 'react'

import { useField } from 'formik'
import styled from 'styled-components'

import Select, { SelectInputProps } from 'components/Input/Select/Select'

interface Props extends SelectInputProps {
  name: string
}

const StyledField = styled(Select)`
  &:not(:last-child) {
    margin-bottom: 22px;
  }
`

const SelectFormField: React.FC<Props> = ({ name, ...props }) => {
  const [field, meta] = useField<string[]>(name)
  return (
    <StyledField
      {...field}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      fullWidth
      required
      {...props}
    />
  )
}

export default memo(SelectFormField)
