import i18n from 'i18next'
import { isUndefined, omitBy } from 'lodash'
import { from, Observable, of, OperatorFunction, throwError, timer } from 'rxjs'
import { ajax as rxAjax, AjaxConfig, AjaxError, AjaxResponse } from 'rxjs/ajax'
import { AjaxCreationMethod } from 'rxjs/internal/ajax/ajax'
import { catchError, filter, map, mergeMap, retryWhen, switchMap, tap } from 'rxjs/operators'

import keycloak from 'services/api/keycloak/keycloak.service'
import Notification from 'utils/notification'

export type RequestInterceptor = OperatorFunction<AjaxConfig, AjaxConfig>
export type ResponseInterceptor = OperatorFunction<AjaxResponse<unknown>, AjaxResponse<unknown>>
export type ErrorInterceptor = OperatorFunction<AjaxError, any>
export type AjaxFn<T> = (params: AjaxConfig) => Observable<AjaxResponse<T>>

export const getToken =
  process.env.NODE_ENV === 'test' ? () => 'TEST_TOKEN' : () => keycloak.getKeycloakInstance().token

export const getAuthorizationHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
})

const passRequestHeaders: RequestInterceptor = map((request) => ({
  ...request,
  headers: omitBy(
    {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      ...request.headers,
    },
    isUndefined,
  ),
}))

const catchAuthErrors: ErrorInterceptor = switchMap((error) => {
  if (error.status === 401) {
    const toastId = 'AUTH_ERROR'

    if (!Notification.isActive(toastId)) {
      Notification.showFailure({
        text: i18n.t('notifications.authError'),
        subText: i18n.t('notifications.windowWillBeReloaded'),
        options: { toastId },
      })
    }

    return timer(3000).pipe(tap(() => window.location.reload()))
  } else {
    throw error
  }
})

const RETRY_LIMIT = 3

export const wrapAjax =
  (
    requestInterceptors: [RequestInterceptor],
    responseInterceptors: [],
    errorInterceptors: [ErrorInterceptor],
    ajax: AjaxCreationMethod,
  ): AjaxFn<any> =>
  (params: AjaxConfig) =>
    of(params).pipe(
      ...requestInterceptors,
      switchMap((params: AjaxConfig) => ajax(params).pipe(...responseInterceptors)),
      retryWhen((error) =>
        error.pipe(
          filter((errors, index) => index < RETRY_LIMIT),
          mergeMap((error) => {
            if (error?.status === 401) {
              return from(keycloak.refreshToken()).pipe(
                mergeMap((value) => {
                  if (value) {
                    return of(value)
                  }

                  return throwError(error)
                }),
                catchError((error) => {
                  console.error(error)
                  return throwError(error)
                }),
              )
            }

            return throwError(error)
          }),
        ),
      ),
      catchError((error) => {
        return errorInterceptors.length ? of(error).pipe(...errorInterceptors) : throwError(error)
      }),
    )

const ajax = wrapAjax([passRequestHeaders], [], [catchAuthErrors], rxAjax)

export const ajaxGetImg = (url: string) => {
  return ajax({
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': undefined,
    },
    responseType: 'arraybuffer',
  }).pipe(
    map((res) => {
      const buffer = res.response
      const blob = new Blob([buffer], { type: 'image/jpeg' })
      return URL.createObjectURL(blob)
    }),
  )
}

export default ajax
