import { ProjectCreationEvent } from 'services/projects/project.types'

export enum TypesOfIDE {
  JUPYTERLAB = 'jupyter',
  RSTUDIO = 'rstudio',
}

export interface WorkspaceDto {
  imageTag: string
  libImageName: string
  cpu?: number
  ram?: number
  enableGpu?: boolean
  deactivateAt?: string
  libNfsDir?: string
  libMountDir?: string
}

export interface WorkspaceCreationEvent extends Omit<ProjectCreationEvent, 'type'> {
  type: 'workspace'
}
