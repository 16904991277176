import { useSelector } from 'react-redux'

import omit from 'lodash/omit'

import { getCurrentUserPermissions } from 'services/api/users/users.selectors'

import { allPermissionsByLevelMatched, allPermissionsMatch, flattenPermissionsByLevel } from './utils/user.helpers'
import { Permissions, RequiredPermissionsByLevel } from './utils/user.types'

export const usePermissionForCurrentUser = (requiredPermissions: RequiredPermissionsByLevel) => {
  const userPermissions = useSelector(getCurrentUserPermissions)
  if (!requiredPermissions) {
    return true
  }
  const anyLevelPermissionsMet = requiredPermissions._anyLevel
    ? allPermissionsMatch(requiredPermissions._anyLevel, flattenPermissionsByLevel(userPermissions))
    : true
  const allLevelsPermissionsMet = allPermissionsByLevelMatched(omit(requiredPermissions, '_anyLevel'), userPermissions)
  // if permissions were required at project level, it's also sufficient if user meets them on global level
  const permissionsMetAtGlobalLevel = allPermissionsMatch(
    flattenPermissionsByLevel(requiredPermissions),
    userPermissions._global,
  )
  return anyLevelPermissionsMet && (allLevelsPermissionsMet || permissionsMetAtGlobalLevel)
}

export const useFlattenPermissionForCurrentUser = (permissions: RequiredPermissionsByLevel) => {
  const userPermissions = useSelector(getCurrentUserPermissions)
  return allPermissionsMatch(flattenPermissionsByLevel(permissions), flattenPermissionsByLevel(userPermissions))
}

export const useProjectManagePermission = (projectName?: string) => {
  return usePermissionForCurrentUser({ [projectName || '_global']: [Permissions.PROJECTS_MANAGE] })
}
