import React, { memo } from 'react'

import styled from 'styled-components'

import { Typography } from '@mui/material'

const StyledTypography = styled(Typography)<{ error?: boolean }>`
  display: block;
  text-align: center;
  margin-top: 4px;
  color: ${({ error, theme }) => (error ? theme.palette.error.main : 'black')};
`

interface Props {
  text: string
  error?: boolean
}

const OptionalLabel: React.FC<Props> = ({ text, error }) => {
  return (
    <StyledTypography error={error} variant="caption">
      {text}
    </StyledTypography>
  )
}

export default memo(OptionalLabel)
