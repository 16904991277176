import React, { memo } from 'react'

import Tooltip, { TooltipProps } from '@mui/material/Tooltip'

interface Props extends TooltipProps {
  isTooltipVisible?: boolean
  title: string
}

const WithTooltip: React.FC<Props> = ({ children, isTooltipVisible = true, ...props }) => {
  return isTooltipVisible ? <Tooltip {...props}>{children}</Tooltip> : children
}

export default memo(WithTooltip)
