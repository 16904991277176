import { capitalize, compose, lowerCase } from 'lodash/fp'
import uniq from 'lodash/fp/uniq'

import { usePermissionForCurrentUser } from 'services/api/users/users.hooks'
import { Project } from 'services/projects/project.types'

import { Permissions, PermissionsByLevel, RequiredPermissionsByLevel, UserRoles } from './user.types'

export const roleToDisplayRole = compose(capitalize, lowerCase)

export const stringsToUserRoles = (roles: string[]) => {
  return roles.map((role) => ({
    id: role,
    displayName: roleToDisplayRole(role),
  }))
}

interface ItemWithPermissions {
  [key: string]: any

  permissions?: PermissionsByLevel
  oneOfPermissions?: PermissionsByLevel[]
}

/**
 * @param {PermissionsByLevel} permissionsByLevel permissions to be flatten
 * @param {string[]} levels an array of names of levels to be flatten
 * @returns {Permissions[]} an array of permissions, flatten from provided levels. If no levels provided, all permissions are flatten and returned
 */
export const flattenPermissionsByLevel = (permissionsByLevel: PermissionsByLevel, levels?: string[]) =>
  uniq(
    (levels ? levels : Object.keys(permissionsByLevel)).reduce((result: Permissions[], level) => {
      return [...result, ...permissionsByLevel[level]!]
    }, []),
  )

export const allPermissionsMatch = (requiredPermissions: Permissions[], currentPermissions?: Permissions[]) => {
  return requiredPermissions.every((permission) => {
    return (currentPermissions || []).includes(permission)
  })
}

export const allPermissionsByLevelMatched = (
  requiredPermissions: RequiredPermissionsByLevel,
  currentPermissions: PermissionsByLevel,
) => {
  return Object.keys(requiredPermissions)
    .filter((key) => requiredPermissions.hasOwnProperty(key))
    .every((key: string) => {
      return allPermissionsMatch(
        requiredPermissions[key]!,
        key === '_anyLevel' ? flattenPermissionsByLevel(currentPermissions) : currentPermissions[key],
      )
    })
}

export const atLeastOnePermissionsByLevelMatched = (
  requiredPermissionsArray: RequiredPermissionsByLevel[],
  currentPermissions: PermissionsByLevel,
) => {
  return requiredPermissionsArray.some((requiredPermissions) => {
    const globalMatched = allPermissionsMatch(
      flattenPermissionsByLevel(requiredPermissions),
      currentPermissions._global,
    )
    const byLevelMatched = allPermissionsByLevelMatched(requiredPermissions, currentPermissions)
    return byLevelMatched || globalMatched
  })
}

export const getItemsAllowedByUserPermissions = <T extends ItemWithPermissions>(
  items: T[],
  userPermissions: PermissionsByLevel,
): T[] =>
  items
    .filter((item) => {
      return !item.permissions || usePermissionForCurrentUser(item.permissions)
    })
    .filter((item) => {
      return !item.oneOfPermissions || atLeastOnePermissionsByLevelMatched(item.oneOfPermissions, userPermissions)
    })

const permissionsForProjectMatched = (
  projectName: string,
  permissions: Permissions[],
  userPermissions: PermissionsByLevel,
) =>
  allPermissionsMatch(permissions, userPermissions._global) ||
  allPermissionsMatch(permissions, userPermissions[projectName])

export const filterProjectsByPermissions = (
  projects: Project[],
  permissions: Permissions[],
  userPermissions: PermissionsByLevel,
) => projects.filter((project) => permissionsForProjectMatched(project.id, permissions, userPermissions))

export const permissionsByRoleMap: Record<UserRoles, Permissions[]> = {
  [UserRoles.ADMIN]: [
    Permissions.ACHIEVEMENTS_MANAGE,
    Permissions.APPLICATIONS_EDIT,
    Permissions.APPLICATIONS_MANAGE,
    Permissions.ASSESSMENTS,
    Permissions.ASSESSMENTS_ANSWERS_FILL,
    Permissions.ASSESSMENTS_ANSWERS_VIEW,
    Permissions.ASSESSMENTS_MANAGE,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.ASSETS_INSTALL_IN_PLATFORM,
    Permissions.ASSETS_MANAGE,
    Permissions.CERTIFICATES,
    Permissions.DASHBOARD_BUILDER,
    Permissions.DASHBOARD_OVERVIEW,
    Permissions.DATASOURCES_READ,
    Permissions.DATASOURCES_MANAGE,
    Permissions.DATA_CATALOG,
    Permissions.DEPLOYMENTS_MANAGE,
    Permissions.DESIGN,
    Permissions.ENDPOINTS_EDIT,
    Permissions.GLOBAL_ASSESSMENTS_ANSWERS_VIEW,
    Permissions.GLOBAL_ASSESSMENTS_MANAGE,
    Permissions.GLOBAL_LIFECYCLES_EDIT,
    Permissions.GLOBAL_LIFECYCLES_VIEW,
    Permissions.GLOBAL_RULES_MANAGE,
    Permissions.GOV_STREAMS,
    Permissions.GOV_STREAMS_MANAGE,
    Permissions.IMAGES,
    Permissions.MANAGE_USER_ACCESS_TOKEN,
    Permissions.MLOPS_OVERVIEW,
    Permissions.MODELS_DEPLOYED_READ,
    Permissions.MODELS_REGISTRY_READ,
    Permissions.MODELS_REGISTRY_CREATE_HOSTED,
    Permissions.MODELS_SNAPSHOT_READ,
    Permissions.MODELS_DEPLOY,
    Permissions.MODELS_MANAGE,
    Permissions.MODEL_ASSESSMENTS_MANAGE,
    Permissions.MODEL_CARD_READ,
    Permissions.MODEL_CARD_CONFIGURE,
    Permissions.NOTIFICATION_CENTER_MANAGE,
    Permissions.PIPELINES,
    Permissions.PIPELINES_MANAGE,
    Permissions.PLATFORM_RESOURCES,
    Permissions.PLATFORM_SETTINGS_READ,
    Permissions.PROJECTS_VIEW,
    Permissions.PROJECTS_CREATE,
    Permissions.PROJECTS_MANAGE,
    Permissions.PROJECT_ASSESSMENTS_MANAGE,
    Permissions.PROJECT_DESCRIPTION_EDIT,
    Permissions.PROJECT_LIFECYCLES_EDIT,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.PROJECT_REGISTRY_VIEW,
    Permissions.PROJECT_USERS_EDIT,
    Permissions.PROPERTIES_EDIT,
    Permissions.PROPERTY_STORE_READ,
    Permissions.REPORTS,
    Permissions.RULES_MANAGE,
    Permissions.RULE_SETS,
    Permissions.TOKEN_REGENERATE,
    Permissions.USERS,
    Permissions.WORKSPACES_ALL,
    Permissions.WORKSPACE_PROJECT_ACTIVATION,
    Permissions.WORKSPACES_MANAGE_ACTIVATIONS,
    Permissions.LISTS_MANAGE,
    Permissions.REALMS,
  ],
  [UserRoles.AI_LEAD]: [
    Permissions.PROJECTS_VIEW,
    Permissions.MODELS_REGISTRY_READ,
    Permissions.MODELS_DEPLOY,
    Permissions.MODELS_MANAGE,
    Permissions.MODEL_ASSESSMENTS_MANAGE,
    Permissions.MODEL_CARD_READ,
    Permissions.PROJECT_REGISTRY_VIEW,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.REPORTS,
    Permissions.MODEL_ASSESSMENTS_FILL,
    Permissions.GOV_STREAMS,
  ],
  [UserRoles.PLATFORM_ADMIN]: [
    Permissions.APPLICATIONS_MANAGE,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.ASSETS_INSTALL_IN_PLATFORM,
    Permissions.ASSETS_MANAGE,
    Permissions.DASHBOARD_BUILDER,
    Permissions.DASHBOARD_OVERVIEW,
    Permissions.DESIGN,
    Permissions.IMAGES,
    Permissions.MANAGE_USER_ACCESS_TOKEN,
    Permissions.MLOPS_OVERVIEW,
    Permissions.MODELS_MANAGE,
    Permissions.MODEL_CARD_READ,
    Permissions.PLATFORM_RESOURCES,
    Permissions.PLATFORM_SETTINGS_READ,
    Permissions.PROJECTS_VIEW,
    Permissions.PROJECTS_CREATE,
    Permissions.PROJECTS_MANAGE,
    Permissions.PROJECT_USERS_EDIT,
    Permissions.PROPERTIES_EDIT,
    Permissions.PROPERTY_STORE_READ,
    Permissions.REPORTS,
    Permissions.TOKEN_REGENERATE,
    Permissions.USERS,
    Permissions.WORKSPACES_ALL,
    Permissions.WORKSPACES_MANAGE_ACTIVATIONS,
  ],
  [UserRoles.PROJECT_ADMIN]: [
    Permissions.APPLICATIONS_READ,
    Permissions.ASSESSMENTS,
    Permissions.ASSESSMENTS_ANSWERS_FILL,
    Permissions.ASSESSMENTS_ANSWERS_VIEW,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.CERTIFICATES,
    Permissions.DATASOURCES_READ,
    Permissions.DATASOURCES_MANAGE,
    Permissions.ENDPOINTS_EDIT,
    Permissions.GLOBAL_ASSESSMENTS_ANSWERS_VIEW,
    Permissions.GLOBAL_ASSESSMENTS_MANAGE,
    Permissions.GLOBAL_LIFECYCLES_VIEW,
    Permissions.GOV_STREAMS,
    Permissions.GOV_STREAMS_MANAGE,
    Permissions.MODEL_CARD_READ,
    Permissions.MODELS_REGISTRY_READ,
    Permissions.MLOPS_OVERVIEW,
    Permissions.NOTIFICATION_CENTER_MANAGE,
    Permissions.PLATFORM_SETTINGS_READ,
    Permissions.PROJECTS_MANAGE,
    Permissions.PROJECTS_VIEW,
    Permissions.PROJECT_ASSESSMENTS_MANAGE,
    Permissions.PROJECT_DESCRIPTION_EDIT,
    Permissions.PROJECT_LIFECYCLES_EDIT,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.PROJECT_REGISTRY_VIEW,
    Permissions.PROPERTIES_EDIT,
    Permissions.PROPERTY_STORE_READ,
    Permissions.RULE_SETS,
    Permissions.REPORTS,
    Permissions.TOKEN_REGENERATE,
    Permissions.WORKSPACES_ALL,
    Permissions.WORKSPACES_MANAGE_ACTIVATIONS,
    Permissions.ONBOARDING_ASSESSMENTS_MANAGE,
  ],
  [UserRoles.DATA_SCIENTIST]: [
    Permissions.APPLICATIONS_EDIT,
    Permissions.ASSESSMENTS,
    Permissions.ASSESSMENTS_ANSWERS_FILL,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.DATASOURCES_READ,
    Permissions.DATASOURCES_MANAGE,
    Permissions.DATA_CATALOG,
    Permissions.ENDPOINTS_EDIT,
    Permissions.GOV_STREAMS,
    Permissions.GOV_STREAMS_MANAGE,
    Permissions.MLOPS_OVERVIEW,
    Permissions.MODELS_MANAGE,
    Permissions.MODELS_DEPLOYED_READ,
    Permissions.MODELS_REGISTRY_READ,
    Permissions.MODELS_REGISTRY_CREATE_HOSTED,
    Permissions.MODELS_SNAPSHOT_READ,
    Permissions.MODEL_CARD_READ,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.NOTIFICATION_CENTER_MANAGE,
    Permissions.PIPELINES,
    Permissions.PROJECT_REGISTRY_VIEW,
    Permissions.PROPERTIES_EDIT,
    Permissions.PROPERTY_STORE_READ,
    Permissions.REPORTS,
    Permissions.WORKSPACE_PROJECT_ACTIVATION,
  ],
  [UserRoles.MODEL_DEPLOYER]: [
    Permissions.APPLICATIONS_EDIT,
    Permissions.ASSESSMENTS,
    Permissions.ASSESSMENTS_ANSWERS_FILL,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.DATASOURCES_READ,
    Permissions.DATASOURCES_MANAGE,
    Permissions.ENDPOINTS_EDIT,
    Permissions.GOV_STREAMS,
    Permissions.GOV_STREAMS_MANAGE,
    Permissions.MLOPS_OVERVIEW,
    Permissions.MODELS_DEPLOYED_READ,
    Permissions.MODELS_REGISTRY_READ,
    Permissions.MODELS_REGISTRY_CREATE_HOSTED,
    Permissions.MODELS_SNAPSHOT_READ,
    Permissions.MODELS_DEPLOY,
    Permissions.MODELS_MANAGE,
    Permissions.MODEL_CARD_READ,
    Permissions.MODEL_CARD_CONFIGURE,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.PIPELINES,
    Permissions.PROPERTIES_EDIT,
    Permissions.PROPERTY_STORE_READ,
    Permissions.PROJECT_REGISTRY_VIEW,
    Permissions.REPORTS,
    Permissions.WORKSPACE_PROJECT_ACTIVATION,
  ],
  [UserRoles.DATA_ENGINEER]: [
    Permissions.ASSESSMENTS,
    Permissions.ASSESSMENTS_ANSWERS_FILL,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.DATASOURCES_READ,
    Permissions.DATASOURCES_MANAGE,
    Permissions.DATA_CATALOG,
    Permissions.ENDPOINTS_EDIT,
    Permissions.GOV_STREAMS,
    Permissions.MLOPS_OVERVIEW,
    Permissions.MODELS_REGISTRY_READ,
    Permissions.MODEL_CARD_READ,
    Permissions.MODEL_CARD_CONFIGURE,
    Permissions.NOTIFICATION_CENTER_MANAGE,
    Permissions.PIPELINES,
    Permissions.PIPELINES_MANAGE,
    Permissions.PROJECTS_MANAGE,
    Permissions.PROJECT_REGISTRY_VIEW,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.PROPERTIES_EDIT,
    Permissions.PROPERTY_STORE_READ,
    Permissions.REPORTS,
    Permissions.WORKSPACE_PROJECT_ACTIVATION,
  ],
  [UserRoles.COMPLIANCE_OFFICER]: [
    Permissions.ACHIEVEMENTS_MANAGE,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.DASHBOARD_OVERVIEW,
    Permissions.GLOBAL_ASSESSMENTS_ANSWERS_VIEW,
    Permissions.GLOBAL_ASSESSMENTS_MANAGE,
    Permissions.GLOBAL_LIFECYCLES_EDIT,
    Permissions.GLOBAL_LIFECYCLES_VIEW,
    Permissions.GLOBAL_RULES_MANAGE,
    Permissions.PROJECTS_MANAGE,
    Permissions.PROJECT_ASSESSMENTS_MANAGE,
    Permissions.PROJECT_LIFECYCLES_EDIT,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.PROPERTIES_EDIT,
    Permissions.PROPERTY_STORE_READ,
    Permissions.REPORTS,
    Permissions.LISTS_MANAGE,
  ],
  [UserRoles.PROJECT_COMPLIANCE_OFFICER]: [
    Permissions.ASSESSMENTS,
    Permissions.ASSESSMENTS_ANSWERS_FILL,
    Permissions.ASSESSMENTS_ANSWERS_VIEW,
    Permissions.ASSESSMENTS_MANAGE,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.CERTIFICATES,
    Permissions.ENDPOINTS_EDIT,
    Permissions.GLOBAL_LIFECYCLES_VIEW,
    Permissions.GOV_STREAMS,
    Permissions.GOV_STREAMS_MANAGE,
    Permissions.MLOPS_OVERVIEW,
    Permissions.MODELS_DEPLOYED_READ,
    Permissions.MODELS_REGISTRY_READ,
    Permissions.MODELS_REGISTRY_CREATE_HOSTED,
    Permissions.MODELS_SNAPSHOT_READ,
    Permissions.MODEL_CARD_READ,
    Permissions.MODEL_CARD_CONFIGURE,
    Permissions.NOTIFICATION_CENTER_MANAGE,
    Permissions.PROJECTS_MANAGE,
    Permissions.PROJECT_ASSESSMENTS_MANAGE,
    Permissions.PROJECT_LIFECYCLES_EDIT,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.PROJECT_REGISTRY_VIEW,
    Permissions.PROPERTIES_EDIT,
    Permissions.PROPERTY_STORE_READ,
    Permissions.REPORTS,
    Permissions.RULES_MANAGE,
    Permissions.RULE_SETS,
    Permissions.ONBOARDING_ASSESSMENTS_MANAGE,
  ],
  [UserRoles.PROJECT_VIEWER]: [
    Permissions.APPLICATIONS_READ,
    Permissions.ASSESSMENTS_ANSWERS_FILL,
    Permissions.ASSETS_INSTALL_IN_PROJECT,
    Permissions.CERTIFICATES,
    Permissions.DATASOURCES_READ,
    Permissions.DATA_CATALOG,
    Permissions.ENDPOINTS_READ,
    Permissions.GOV_STREAMS,
    Permissions.MODELS_DEPLOYED_READ,
    Permissions.MODELS_REGISTRY_READ,
    Permissions.MODELS_REGISTRY_CREATE_HOSTED,
    Permissions.MODELS_SNAPSHOT_READ,
    Permissions.MODEL_CARD_READ,
    Permissions.PROJECT_LIFECYCLES_VIEW,
    Permissions.NOTIFICATION_CENTER_READ,
    Permissions.PIPELINES,
    Permissions.PROJECT_REGISTRY_VIEW,
    Permissions.REPORTS,
    Permissions.RULE_SETS,
  ],
  [UserRoles.APPLICATION_VIEWER]: [
    Permissions.APPLICATIONS_READ,
    Permissions.ENDPOINTS_READ,
    Permissions.PROJECT_REGISTRY_VIEW,
  ],
}
