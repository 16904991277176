import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomButtonProps } from './DialogActions.types'

import { MarginDismissButton } from './DialogActions.styles'

interface Props {
  id: string
  label?: string
  buttonProps?: CustomButtonProps
  className?: string
}

const DismissButton: React.FC<Props> = ({ id, label, buttonProps = {}, className }) => {
  const [t] = useTranslation()

  return (
    <MarginDismissButton id={`${id}Cancel`} color="secondary" data-cy="dismiss" {...buttonProps} className={className}>
      {label || t('cancel')}
    </MarginDismissButton>
  )
}

export default memo(DismissButton)
