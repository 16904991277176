import React, { ReactNode, useCallback, useMemo, useRef } from 'react'

import { Button } from '@mui/material'

import InlineChips from 'components/InlineChips/InlineChips'

interface SharedProps {
  onChange: (file: File | File[] | null) => void
  label: string
  multiple?: boolean
  accept?: string
  helperText?: string
  name?: string
  id: string
  inputButton?: boolean
  startIcon?: ReactNode
}

export interface SingleFileInputProps extends SharedProps {
  multiple?: false
  value?: File | null
}

export interface MultipleFilesInputProps extends SharedProps {
  multiple: true
  value?: File[] | null
}

export type FileInputProps = SingleFileInputProps | MultipleFilesInputProps

const FileInput: React.FC<FileInputProps> = ({
  value,
  label,
  onChange,
  multiple,
  accept,
  name,
  helperText,
  id,
  inputButton,
  startIcon,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const filesList = useMemo(() => {
    return Array.isArray(value) ? value : value ? [value] : []
  }, [value])
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from<File>(e.target.files || [])

      if (multiple) {
        onChange([...filesList, ...files])
      } else {
        onChange(files[0])
      }

      e.target.value = ''
    },
    [multiple, onChange, filesList],
  )
  const handleAdd: any = useMemo(() => {
    if (!multiple && value) {
      return undefined
    }

    return () => inputRef.current!.click()
  }, [multiple, value])
  const handleDelete = useCallback(
    ({ file }: any) => {
      if (multiple) {
        const fileIndex = filesList.indexOf(file)
        const newValue = [...filesList]
        newValue.splice(fileIndex, 1)
        onChange(newValue)
      } else {
        onChange(null)
      }
    },
    [filesList, onChange, multiple],
  )
  const chips = useMemo(() => filesList.map((file) => ({ label: file.name, file })), [filesList])

  return (
    <>
      <input
        ref={inputRef}
        name={name}
        hidden
        accept={accept}
        multiple={multiple}
        type="file"
        onChange={handleChange}
        id={id}
      />
      {inputButton ? (
        <Button data-cy="choose-file" size="small" color="secondary" onClick={handleAdd} startIcon={startIcon}>
          {label}
        </Button>
      ) : (
        <InlineChips
          id={`${id}ChipsFiles`}
          label={label}
          onAdd={handleAdd}
          onDelete={handleDelete}
          chips={chips}
          helper={helperText}
        />
      )}
    </>
  )
}

export default FileInput
