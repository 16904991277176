import styled from 'styled-components'

import { Button, DialogActions as MuiDialogActions } from '@mui/material'

const NoMarginButton = styled(Button)`
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
  word-break: keep-all;
`

const MarginDismissButton = styled(NoMarginButton)`
  margin-right: 16px;
`

const StyledMuiDialogActions = styled(MuiDialogActions)`
  padding: 16px 24px 24px;
`
export { NoMarginButton, MarginDismissButton, StyledMuiDialogActions }
