import styled from 'styled-components'

import { Grid } from '@mui/material'

const ChipsGroups = styled.div`
  margin-top: 16px;
`

const GridRow = styled(Grid)`
  & + & {
    margin-top: 4px;
    margin-bottom: 10px;
  }
`

const RowsSectionWrapper = styled(Grid)`
  &:not(:last-child) {
    margin-bottom: 34px;
  }
`

const Cell = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`

export { ChipsGroups, GridRow, Cell, RowsSectionWrapper }
