import styled from 'styled-components'

const InputContainer = styled.div`
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  > * {
    width: 100%;
  }
`

export const HalfInputContainer = styled(InputContainer)`
  width: 49%;
`

export const ProjectHalfInputContainer = styled(HalfInputContainer)`
  margin-right: 0;
  margin-bottom: 16px;

  &:nth-child(even) {
    padding-left: 12px;
  }
  &:nth-child(odd) {
    padding-right: 12px;
  }
`

export const FullInputContainer = styled(InputContainer)`
  width: 100%;
`
