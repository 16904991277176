import { useEffect, useState } from 'react'

import { noop } from 'lodash'

import { ajaxGetImg } from 'utils/ajax'

const cachedImages: Record<string, string | undefined> = {}

export const useImageUrl = (url?: string) => {
  const [image, setImage] = useState<string | undefined>()
  useEffect(() => {
    if (!url) {
      return
    }
    if (cachedImages[url]) {
      setImage(cachedImages[url])
      return
    }
    const subscription = ajaxGetImg(url).subscribe((img: string) => {
      setImage(img)
      cachedImages[url] = img
    }, noop)
    return () => subscription.unsubscribe()
  }, [url])
  return image
}

export const purgeFromImageCache = (url: string) => {
  cachedImages[url] = undefined
}
